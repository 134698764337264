<template>
  <main-template>
    <v-row>
      <v-btn
        color="primary"
        x-small
        text
        @click="() => $router.push({ name: 'home' })"
        :disabled="submittingForm"
      >
        <v-icon left> fa fa-angle-left </v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <h1 class="text-h4">Matriculas</h1>
        <div class="d-flex mb-2 justify-space-between">
          <v-text-field
            v-model="search"
            class="search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="matriculasList"
          :loading="carregandoMatriculas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="matricula.estaDeletado ? ['deleted'] : []"
                v-for="matricula of items"
                :key="matricula.id"
              >
                <td>{{ matricula.id }}</td>
                <td>{{ matricula.professor.nome }}</td>
                <td>{{ matricula.turma.descricao }}</td>
                <td>
                  <div class="text-left">
                    <v-menu :close-on-content-click="false" :nudge-width="100" bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small color="blue" class="white--text" v-bind="attrs" v-on="on">
                          AÇÕES
                        </v-btn>
                      </template>

                      <v-card class="mx-auto" max-width="300">
                        <v-list>
                          <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                            <v-list-item @click="() => deletarMatricula(matricula)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-trash</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Apagar matricula</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                        <v-divider></v-divider>
                      </v-card>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <ModalCarteira :dialog="dialog" :pdf="pdf" />
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";
import Matricula from "@/Models/Matricula";

import ModalCarteira from "@/components/Matriculas/EModalCarteira.vue";

export default {
  components: {
    ModalCarteira,
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      dialog: false,
      pdf: null,
      carregandoMatriculas: false,
      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      matriculasList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Professor", value: "professor" },
          { text: "Turma", value: "nomeTurma" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },

  mounted() {
    this.listarMatriculas(this.pageNumber);
  },

  watch: {
    carregandoMatriculas(value) {
      if (value && this.matriculasList.length === 0) {
        this.$loaderService.open("Carregando Matriculas");
      } else {
        this.$loaderService.close();
      }
    },

    async search(val) {
      if (val.length > 1) {
        await this.listarMatriculas(0, val);
      }
      if (!val) {
        await this.listarMatriculas(0);
      }
    },
  },

  methods: {
    async listarMatriculas(pageNumber, query = null) {
      this.carregandoMatriculas = true;
      this.$loaderService.open("Carregando Matriculas");
      try {
        const response = await this.$services.diarioFormacaoService.syncAllMatriculas();
        this.matriculasList = response;
        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoMatriculas = false;
      this.$loaderService.close();
    },

    criarMatricula() {
      this.$router.push({ name: "matriculas.create" });
    },

    editarMatricula(matricula) {
      this.$router.push({ name: "matriculas.edit", params: { matricula_id: matricula.id } });
    },

    async boletimMatricula(matricula) {
      this.$loaderService.open("Baixando boletim");
      try {
        await this.$services.relatoriosService.boletimIndividual(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async declaracaoCurso(matricula) {
      this.$loaderService.open("Baixando declaração");
      try {
        await this.$services.relatoriosService.declaracaoCurso(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async declaracaoConclusao(matricula) {
      this.$loaderService.open("Baixando declaração");
      try {
        await this.$services.relatoriosService.declaracaoConclusao(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async fichaDeMatricula(matricula) {
      this.$loaderService.open("Baixando ficha de matricula");
      try {
        await this.$services.relatoriosService.fichaDeMatricula(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async deletarMatricula(matricula) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta matricula?",
        confirmationCode: matricula.id,
        confirmationMessage: `Por favor, digite <strong>${matricula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Matricula");
              try {
                await this.$services.diarioFormacaoService.deletarMatricula(matricula);
                this.listarMatriculas();
                this.$toast.success("Matricula deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },

    recuperarMatricula(matricula) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta matricula?",
        confirmationCode: matricula.id,
        confirmationMessage: `Por favor, digite <strong>${matricula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Matricula");
              try {
                await this.$services.matriculasService.recuperarMatricula(matricula);
                this.$toast.success("Matricula recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },

    async carteira(matricula) {
      // this.pdf = await this.$services.relatoriosService.exibirCarteira(matricula);
      // this.pdf += "#zoom=150";

      this.$loaderService.open("Baixando carteira");
      try {
        await this.$services.relatoriosService.carteira(matricula);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
