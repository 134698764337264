<template>
  <div>
    <v-navigation-drawer width="400" v-model="localDrawer" right temporary app>
      <v-list-item :class="localAula.color">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{
            localAula.tipo_de_aula
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Data</v-list-item-title>
          <p class="font-weight-lsapn/spanght text-justify">
            {{ localAula.data.split("-").reverse().join("/") }}
            {{ localAula.horario_inicio }} - {{ localAula.horario_final }}
          </p>
          <v-list-item-title class="mt-4">Projeto</v-list-item-title>
          <ul>
            <li>
              {{ localAula.projeto }}
            </li>
          </ul>
          <v-divider></v-divider>
          <v-list-item-title class="mt-4">Componente Curricular</v-list-item-title>
          <ul>
            <li>
              {{ localAula.componente_curricular }}
            </li>
          </ul>
          <v-divider></v-divider>
          <v-list-item-title class="mt-4">Ano/Etapa/Modalidade</v-list-item-title>
          <v-list-item-title>
            <ul>
              <li>
                {{ localAula.ano_etapa_modalidade }}
              </li>
            </ul>
          </v-list-item-title>
          <v-divider></v-divider>
          <v-list-item-title class="mt-4">Tema</v-list-item-title>
          <p class="font-weight-light text-justify">
            {{ localAula.tema }}
          </p>
          <v-divider></v-divider>
          <v-list-item-title class="mt-4">Objetivo</v-list-item-title>
          <p class="font-weight-light text-justify">
            {{ localAula.objetivo }}
          </p>
          <v-divider></v-divider>
          <v-list-item-title class="mt-4 mb-3">Agenda</v-list-item-title>
          <v-tooltip v-if="localAula.agenda != 'null'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                @click="verDocAgenda(localAula.id)"
                class="ml-2"
                color="red lighten-5"
                v-bind="attrs"
                v-on="on"
                >Agenda
                <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
              </v-btn>
            </template>
            <span>Ver documento</span>
          </v-tooltip>
          <span v-else>Nenhuma Agenda</span>
          <v-divider></v-divider>
          <v-list-item-title class="mt-4 mb-3">PPT</v-list-item-title>
          <v-tooltip v-if="localAula.ppt != 'null'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                @click="verDocPPT(localAula.id)"
                class="ml-2"
                color="red lighten-5"
                v-bind="attrs"
                v-on="on"
                >PPT
                <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
              </v-btn>
            </template>
            <span>Ver documento</span>
          </v-tooltip>
          <span v-else>Nenhum PPT</span>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    aula: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    localDrawer() {
      this.$emit("input", this.localDrawer);
    },
  },
  computed: {
    localAula() {
      return this.aula;
    },
  },
  mounted() {},
  data() {
    return {
      localDrawer: this.value,
      dialog: {
        duplicar: false,
        exportar: false,
      },
      submittingForm: false,
    };
  },

  methods: {
    async verDocAgenda(aulaId) {
      this.$loaderService.open("Baixando...");
      try {
        await this.$services.diarioFormacaoService.baixarDocAGenda(parseInt(this.aula.id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async verDocPPT(aulaId) {
      this.$loaderService.open("Baixando...");
      try {
        await this.$services.diarioFormacaoService.baixarDocPpt(parseInt(this.aula.id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
