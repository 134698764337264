<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'saec.franquia', params: { franquia_id } })"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          ARQUIVO A SER REMOVIDO
        </h1>
      </v-col>
    </v-row>
    <v-row>
      {{ edicaoSaec?.descricao }}
      {{ edicaoSaec?.prova }}
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="text-h4">Franquia</span>
      </v-col>
      <v-col cols="12">
        {{ franquia?.descricao }}
      </v-col>
      <v-col cols="12">
        <v-row v-for="(turma, index) of turmas" :key="index">
          <v-col
            v-if="edicaoSaec?.prova.filter((prova) => prova.serie_id === turma.serie_id).length"
          >
            Lançar notas:
            <v-btn color="primary" text @click="lancarNotaDaTurma(turma.id)">
              {{ turma?.descricao }}
            </v-btn>
          </v-col>
          <v-col v-else>
            <v-sheet class="text-center">
              A série <strong>{{ turma.serie?.descricao }}</strong> ainda não tem questoes
              relacionadas ela nesta edição.
              <v-btn color="red" text @click="lancarQuestoesSaec(turma.serie.id)">
                Criar agora
                <v-icon> mdi-alert </v-icon>
              </v-btn>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      franquia_id: "",
      franquia: null,
      edicaoSaec: null,
      edicao_id: "",
      turmasFundamental: [],
      turmasInfantil: [],
      turmas: [],
    };
  },
  async mounted() {
    this.franquia_id = this.$route.params.franquia_id;
    this.edicao_id = this.$route.params.edicao_id;
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.edicaoSaec = await this.$services.saecService.getUmaEdicaoSaec(this.edicao_id);
      this.franquia = await this.$services.franquiasService.getFranquia(this.franquia_id);
      this.turmasFundamental = await this.$services.saecService.getTurmasFundamental(
        this.franquia_id
      );
      this.turmasInfantil = await this.$services.saecService.getTurmasInfantil(this.franquia_id);

      this.turmasFundamental = this.turmasFundamental.sort(this.alpabethicalOrder);
      this.turmasInfantil = this.turmasInfantil.sort(this.alpabethicalOrder);
      this.turmas = [...this.turmasFundamental, ...this.turmasInfantil];
    },
    lancarNotaDaTurma(turma_id) {
      this.$router.push({
        name: "saec.lancarNotasDaTurma",
        params: {
          franquia_id: this.franquia_id,
          edicao_id: this.edicaoSaec.id,
          turma_id,
        },
      });
    },
    lancarQuestoesSaec(serie_id) {
      this.$router.push({
        name: "saec.lancarQuestoesSaec",
        params: {
          edicao_id: this.edicaoSaec.id,
          serie_id,
        },
      });
    },
    alpabethicalOrder(a, b) {
      return a.descricao.localeCompare(b.descricao);
    },
  },
};
</script>

<style></style>
