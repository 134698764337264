<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'saec.lancarQuestoesSaecDaSerie',
                  params: { franquia_id, edicao_id, serie_id },
                })
            "
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editar" : "Nova" }} Questão Para Prova Saec
        </h1>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col class="py-0" cols="12">
        <v-sheet
          rounded
          color="green white--text"
          class="d-flex flex-column flex-wrap ma-2 px-5 py-5"
        >
          <span
            >Você está <strong>{{ editing ? "editando" : "adicionando" }}</strong> uma questão para
          </span>
          <span>{{ edicaoSaec?.descricao }}</span>
          <span>Série: {{ serie?.descricao }}</span>
        </v-sheet>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)">
        <v-row class="ma-3">
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Series" rules="required" v-slot="{ errors }">
              <e-label>Series</e-label>
              <e-autocomplete
                :readonly="true"
                :error-messages="errors"
                :loading="carregandoSeries"
                :items="seriesList"
                :item-value="(item) => item.id"
                :item-text="(item) => item.descricao"
                v-model="form.serie_id"
                label="Selecione uma opção"
                solo
                chips
                small-chips
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Edição Saec" rules="required" v-slot="{ errors }">
              <e-label>Edição</e-label>
              <e-autocomplete
                :readonly="true"
                :error-messages="errors"
                :loading="carregandoEdicoes"
                :items="edicoesList"
                :item-value="(item) => item.id"
                :item-text="(item) => item.descricao"
                v-model="form.edicao_id"
                label="Selecione uma opção"
                solo
                chips
                small-chips
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Disciplina" rules="required" v-slot="{ errors }">
              <e-label>Disciplina</e-label>
              <e-autocomplete
                :error-messages="errors"
                :loading="carregandoDisciplinas"
                :items="disciplinasList"
                :item-value="(item) => item.id"
                :item-text="(item) => item.descricao"
                v-model="form.disciplina_id"
                label="Selecione uma opção"
                solo
                chips
                small-chips
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Código" rules="required" v-slot="{ errors }">
              <e-label>Código</e-label>
              <v-text-field
                :error-messages="errors"
                v-model="form.codigo"
                label="Informe o código"
                solo
                chips
                small-chips
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Habilidade" rules="required" v-slot="{ errors }">
              <e-label>Habilidade</e-label>
              <v-text-field
                v-model="form.habilidade"
                :error-messages="errors"
                label="Informe a habilidade"
                solo
                chips
                small-chips
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <e-label>Enunciado</e-label>
            <v-text-field
              v-model="form.enunciado"
              label="Informe o Enunciado da questão"
              solo
              chips
              small-chips
            ></v-text-field>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Ordem" rules="required" v-slot="{ errors }">
              <e-label>Ordem da questão na prova</e-label>
              <v-text-field
                v-model="form.sequencia"
                :error-messages="errors"
                type="number"
                min="1"
                label="Informe o Ordem"
                solo
                chips
                small-chips
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Gabarito" rules="required" v-slot="{ errors }">
              <e-label>Gabarito</e-label>
              <v-select
                :error-messages="errors"
                :items="alternativas"
                v-model="form.gabarito"
                label="Informe o Gabarito"
                solo
                chips
                small-chips
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" v-for="(item, index) in alternativas" :key="index">
            <v-row>
              <v-col class="pt-0 pb-0" cols="2">
                <ValidationProvider name="Alternativa" rules="required" v-slot="{ errors }">
                  <e-label>Alternativa</e-label>
                  <v-text-field
                    v-model="alternativas[index]"
                    disabled
                    :error-messages="errors"
                    label="Alternativa"
                    solo
                    chips
                    small-chips
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col class="pt-0 pb-0" cols="9">
                <ValidationProvider name="Questão" rules="required" v-slot="{ errors }">
                  <e-label>Questão</e-label>
                  <v-text-field
                    v-model="questoes[index]"
                    :error-messages="errors"
                    label="Descreva a questão"
                    solo
                    chips
                    small-chips
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col class="mt-6" cols="1">
                <v-btn color="error" @click="removerQuestao(index)">X</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn color="primary" @click="adicionarNovaQuestao()">Adicionar nova questão</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      franquia_id: "",
      franquia: null,
      edicao_id: null,
      edicaoSaec: null,
      edicoesList: [],
      disciplinasList: [],
      serie_id: null,
      serie: null,
      seriesList: [],
      questao_id: null,
      form: {},
      carregandoSeries: false,
      submittingForm: false,
      carregandoEdicoes: false,
      carregandoDisciplinas: false,
      alternativas: [],
      questoes: [],
      charCode: 65,
    };
  },
  computed: {
    editing() {
      return this.$route.params.questao_id !== "new";
    },
  },
  async mounted() {
    this.franquia_id = this.$route.params.franquia_id;
    this.edicao_id = this.$route.params.edicao_id;
    this.serie_id = this.$route.params.serie_id;
    this.questao_id = this.$route.params.questao_id;
    this.$loaderService.open("Carregando dados");
    await this.loadData();
    this.$loaderService.close();
  },
  methods: {
    async loadData() {
      this.carregandoSeries = true;
      this.carregandoEdicoes = true;
      this.carregandoDisciplinas = true;

      this.edicaoSaec = await this.$services.saecService.getUmaEdicaoSaec(this.edicao_id);
      this.franquia = await this.$services.franquiasService.getFranquia(this.franquia_id);
      this.serie = await this.$services.saecService.getUmaSerie(this.serie_id);
      this.seriesList = await this.$services.saecService.getSeries(this.franquia_id);
      this.edicoesList = await this.$services.saecService.getEdicoesSaec(this.franquia_id);
      this.disciplinasList = await this.$services.disciplinasService.syncAll();
      if (this.editing) {
        const questao = await this.$services.saecService.getQuestao(this.questao_id);
        this.form = questao;
        this.questoes = questao.questoes;
        this.alternativas = questao.alternativas;
        this.charCode =
          this.alternativas[this.alternativas.length - 1].toString().charCodeAt(0) + 1;
      }

      this.form.edicao_id = this.edicao_id;
      this.form.serie_id = this.serie.id;
      this.carregandoEdicoes = false;
      this.carregandoSeries = false;
      this.carregandoDisciplinas = false;
    },
    async submitForm() {
      this.submittingForm = true;
      this.form.questoes = this.questoes;
      this.form.alternativas = this.alternativas;
      try {
        if (this.editing) {
          this.$services.saecService.atualizarQuestao(this.questao_id, this.form);
        } else {
          await this.$services.saecService.criarQuestao(this.form);
        }
        this.$toast.success("Inscriçao realizada com sucesso");
      } catch (error) {
        this.$toast.danger(error);
        // this.$toast.danger(Object.values(error.errors)[0].toString());
      } finally {
        this.submittingForm = false;
      }

      this.$router.push({
        name: "saec.lancarQuestoesSaecDaSerie",
        params: {
          franquia_id: this.franquia_id,
          edicao_id: this.edicao_id,
          serie_id: this.serie_id,
        },
      });
    },
    adicionarNovaQuestao() {
      this.alternativas.push(String.fromCharCode(this.charCode));
      this.charCode += 1;
      this.questoes.push("");
    },
    removerQuestao(index) {
      this.alternativas.splice(index, 1);
      this.questoes.splice(index, 1);
      this.handleAlternativas();
    },
    handleAlternativas() {
      this.alternativas = [];
      this.charCode = 65;
      this.questoes.forEach(() => {
        this.alternativas.push(String.fromCharCode(this.charCode));
        this.charCode += 1;
      });
    },
  },
};
</script>

<style></style>
