import cep from "cep-promise";

const providers = ["brasilapi", "viacep"];
export class CEPService {
  async buscarDadosDoCep(cepString) {
    const response = await cep(cepString, { providers });
    return {
      cep: response.cep,
      cidade: response.city,
      estado: response.state,
      rua: response.street,
      bairro: response.neighborhood,
      logradouro: response.street,
    };
  }

  getCepInteiro(cepValue) {
    // "99999-999" => 99999999
    const strComNumeros = `${cepValue}`.replace(/\D/g, "");
    if (strComNumeros.length !== 8) return null;
    return parseInt(strComNumeros, 10);
  }
}

export default new CEPService();
