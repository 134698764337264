<template>
  <main-template>
    <v-row>
      <v-col class="pb-0 mb-0">
        <div class="mt-5 mb-0 mb-0">
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'saec',
                })
            "
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          <span class="text-h4"> Gerenciar Edições Saec o ano de {{ user.ano.descricao }} </span>
        </div>
      </v-col>
      <v-col cols="12" class="pb-5">
        <div class="d-flex justify-space-between">
          <v-btn color="success" @click="cadastrarEdicao" class="">Cadastrar Edição</v-btn>
          <v-text-field
            v-model="search"
            class="search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
    </v-row>

    <v-data-table
      :headers="table.headers"
      :items="edicoesList"
      :items-per-page="10"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:body="{ items }">
        <tbody name="scroll-x-transition" is="transition-group" duration="150">
          <tr
            color="primary"
            :class="edicao.estaDeletado ? ['deleted'] : []"
            v-for="edicao of items"
            :key="edicao.id"
          >
            <td>{{ edicao.id }}</td>
            <td>{{ edicao.descricao }}</td>
            <td>{{ edicao.data_prova }}</td>
            <td>
              <v-tooltip v-if="!edicao.estaDeletado" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="blue-grey lighten-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => editarEdicao(edicao.id)"
                  >
                    <v-icon small>fa fa-edit </v-icon>
                  </v-btn>
                </template>
                <span>Editar Questão</span>
              </v-tooltip>
              <v-tooltip v-if="!edicao.estaDeletado" color="error" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => deletarEdicao(edicao.id)"
                  >
                    <v-icon small>fa fa-trash </v-icon>
                  </v-btn>
                </template>
                <span>Apagar Edicao</span>
              </v-tooltip>
              <!-- <v-tooltip v-if="edicao.estaDeletado" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => recuperarEdicao(Edicao)"
                  >
                    <v-icon small>fa fa-sync </v-icon>
                  </v-btn>
                </template>
                <span>Recuperar Edicao</span>
              </v-tooltip> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Descricao", value: "descricao" },
          { text: "Data da Prova", value: "data_prova" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
      dataTableFilter: "",
      search: "",
      franquia: null,
      edicaoSaec: null,
      edicao_id: null,
      edicoesList: [],
      resumo: [],
    };
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    async loadData() {
      this.edicoesList = await this.$services.saecService.getEdicoesSaec();
    },
    cadastrarEdicao() {
      this.$router.push({
        name: "saec.newEditEdicaoSaec",
        params: {
          edicao_id: "new",
        },
      });
    },
    editarEdicao(edicao_id) {
      this.$router.push({
        name: "saec.newEditEdicaoSaec",
        params: {
          edicao_id,
        },
      });
    },
  },
};
</script>

<style></style>
