<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'diario-formacao-encontros',
                  params: { gestaoDeAulaId: $route.params.vinculo },
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Encontro
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green" class="mb-5" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>
              Formador:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <div>
          <v-row>
            <v-col class="pt-0 pb-0" cols="3">
              <ValidationProvider name="Referência" rules="required" v-slot="{ errors }">
                <e-label>Referência</e-label>
                <v-select
                  :error-messages="errors"
                  :items="$constants.referencia_encontros"
                  v-model="aulaSelecionada.referencia"
                  return-object
                  label="Selecione uma referência"
                  solo
                ></v-select>
              </ValidationProvider>
            </v-col>

            <v-col class="pt-0 pb-0" cols="3">
              <ValidationProvider name="Data" rules="required" v-slot="{ errors }">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="data"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <e-label>Selecione uma data</e-label>

                    <v-text-field
                      v-model="aulaSelecionada.data"
                      persistent-hint
                      label="Escolha uma data"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      solo
                      :error-messages="errors"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="data" range scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                    <v-btn text color="primary" @click="customEvent(data)"> OK </v-btn>
                  </v-date-picker>
                </v-dialog>
              </ValidationProvider>
            </v-col>

            <v-col class="pt-0 pb-0" cols="3">
              <ValidationProvider name="Horário da inicio" rules="required" v-slot="{ errors }">
                <e-label>Digite um horário de Início</e-label>
                <v-text-field
                  v-model="aulaSelecionada.horario_inicio"
                  :error-messages="errors"
                  value="12:30:00"
                  type="time"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col class="pt-0 pb-0" cols="3">
              <ValidationProvider name="Horário da termino" rules="required" v-slot="{ errors }">
                <e-label>Digite um horário de Término</e-label>
                <v-text-field
                  v-model="aulaSelecionada.horario_final"
                  :error-messages="errors"
                  value="12:30:00"
                  type="time"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <ValidationProvider name="Projeto">
                <e-label>Projeto</e-label>
                <v-row>
                  <v-col>
                    <div v-for="projet of projetsList" :key="projet.id">
                      <v-switch
                        v-model="aulaSelecionada.projeto"
                        color="primary"
                        :label="projet"
                        :value="projet"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <ValidationProvider name="Componente Curricular">
                <e-label>Componente curricular, campos de experiências ou modalidade: </e-label>
                <v-row>
                  <v-col>
                    <div v-for="projet of compCurricularOptions" :key="projet.id">
                      <v-switch
                        v-model="aulaSelecionada.componente_curricular"
                        color="primary"
                        :label="projet"
                        :value="projet"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <ValidationProvider name="Ano/Etapa">
                <e-label>ANO/ETAPA/MODALIDADE: </e-label>
                <v-row>
                  <v-col>
                    <div v-for="projet of etapasOptions" :key="projet.id">
                      <v-switch
                        v-model="aulaSelecionada.ano_etapa_modalidade"
                        color="primary"
                        :label="projet"
                        :value="projet"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider name="Tema" rules="required" v-slot="{ errors }">
                  <e-label>Tema: </e-label>
                  <v-text-field
                    v-model="aulaSelecionada.tema"
                    persistent-hint
                    label="Digite aqui o Tema"
                    readonly
                    v-bind="attrs"
                    :error-messages="errors"
                    v-on="on"
                    dense
                    solo
                  ></v-text-field>
                </ValidationProvider>
              </template>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <ValidationProvider name="Tema" rules="required" v-slot="{ errors }">
                <e-label>Tema: </e-label>
                <v-text-field solo dense v-model="aulaSelecionada.tema" :error-messages="errors" />
              </ValidationProvider>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <ValidationProvider name="Objetivo" rules="required" v-slot="{ errors }">
                <e-label>Objetivo: </e-label>
                <v-textarea
                  solo
                  dense
                  v-model="aulaSelecionada.objetivo"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <ValidationProvider name="Agenda">
                <e-label>Agenda</e-label>
                <template>
                  <v-file-input
                    v-model="aulaSelecionada.agenda"
                    show-size
                    label="Anexe um arquivo"
                  ></v-file-input>
                </template>
              </ValidationProvider>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <ValidationProvider name="PPT">
                <e-label>PPT: </e-label>
                <template>
                  <v-file-input
                    v-model="aulaSelecionada.ppt"
                    show-size
                    label="Anexe um arquivo"
                  ></v-file-input>
                </template>
              </ValidationProvider>
            </v-col>
          </v-row>
        </div>

        <v-btn
          class="mt-5"
          color="primary"
          block
          :disabled="submittingForm"
          :loading="submittingForm"
          type="submit"
          >Enviar Dados</v-btn
        >
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";
import { $constants } from "../../plugins/constants";
import { dateFormatParam } from "../../plugins/filters";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: Number,
      require: true,
    },
  },
  mounted() {
    this.loadData();
    if (this.editing) {
      this.mostrarCampos = true;
      this.outrosCampos = true;
    }
  },
  data() {
    return {
      objetivo: null,
      tema: null,
      projetsList: [
        "Projeto Educar e Cuidar",
        "Projeto Alfaletrar",
        "Projeto Avançar",
        "Projeto Transformar",
        "Projeto Esperançar",
        "Projeto Integrar",
      ],
      compCurricularOptions: [
        "Eu, o outro e o nós, Corpo, gestos e movimentos, Traços, sons, cores e formas, Escuta, fala, pensamento e imaginação e Espaço, tempo, quantidades, relações e transformações",
        "Língua Portuguesa",
        "Matemática",
        "Geografia",
        "História",
        "Ciências da Natureza",
        "Língua Inglesa",
        "Educação Física",
        "Ensino Religioso",
        "Educação de Jovens E Adultos",
        "Educação Especial e Inclusiva e Equipe Multidisciplinar",
      ],
      etapasOptions: [
        "Creche ",
        "Pré-escola",
        "1º",
        "2º",
        "3º",
        "4º",
        "5º",
        "6º",
        "7º",
        "8º",
        "9º",
        "AEE",
        "EJA",
      ],

      turma: null,
      data: null,

      submittingForm: false,
      form: {},

      dialog: {
        duplicar: false,
        exportar: false,
      },
      horarios: [],
      gestaoDeAula: null,
      modal: null,
      aulaSelecionada: {
        projeto: [],
        componente_curricular: [],
        ano_etapa_modalidade: [],
      },
    };
  },
  watch: {
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(new Date(value));
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    customEvent(data) {
      this.$refs.dialog.save(data);
    },
    dateFormatConvert(data) {
      return dateFormatParam(data);
    },

    async submitForm() {
      if (!this.aulaSelecionada.projeto?.length) {
        this.$toast.danger("Por favor, selecione ao menos um Projeto!");
        return;
      }

      if (!this.aulaSelecionada.componente_curricular?.length) {
        this.$toast.danger(
          "Por favor, selecione ao menos uma opção no campo: Componente curricular, campos de experiências ou modalidade"
        );
        return;
      }

      if (!this.aulaSelecionada.ano_etapa_modalidade?.length) {
        this.$toast.danger(
          "Por favor, selecione ao menos uma opção no campo: Ano/Etapa/Modalidade"
        );
        return;
      }

      this.submittingForm = true;

      this.aulaSelecionada.instrutorDisciplinaTurma_id = parseInt(
        this.$route.params.gestaoDeAulaId,
        10
      );
      try {
        if (this.editing) {
          await this.$services.diarioFormacaoService.atualizarEncontro(this.aulaSelecionada);
          this.$toast.success("Encontro editado com sucesso!");
          this.$router.back();
        } else {
          this.aulaSelecionada.diario_formacao_id = this.$route.params.vinculo;
          await this.$services.diarioFormacaoService.criarEncontro(this.aulaSelecionada);
          this.$toast.success("Encontro cadastrado com sucesso!");
          this.$router.back();
        }
      } catch (error) {
        this.$toast.danger(error);
      }
      this.submittingForm = false;
    },
  },
};
</script>

<style scoped>
.dataInput {
  background-color: white;
  width: 100%;
  height: 35px;
  box-shadow: 2px 2px rgb(0, 0, 0, 0.1);
}
</style>
