<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Formadores</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarFormador" class="mr-2">Cadastrar Formador</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="turmasList"
          :loading="carregandoTurmas"
          :items-per-page="5"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                :class="parseInt(turma.notifiq, 10) === 1 ? 'green lighten-5' : ''"
                v-for="turma of items"
                :key="turma.id"
              >
                <td>{{ turma.id }}</td>
                <td>
                  <v-btn text @click="() => visualizarTurma(turma)">
                    {{ turma.nomecompleto }}
                  </v-btn>
                </td>
                <td class="text-center">{{ turma.email }}</td>
                <td class="text-center">{{ turma.data_nascimento }}</td>
                <td>
                  <v-tooltip v-if="!turma.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarFormador(turma)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <e-selecionar-gestao-modal
      :dialog="dialog"
      :turma_id="turma_id"
      @dialogChange="dialog = $event"
    ></e-selecionar-gestao-modal>
  </main-template>
</template>

<script>
import Turma from "@/Models/Turma";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      carregandoTurmas: false,
      dialog: false,
      dataTableFilter,
      search: "",
      turma_id: null,
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      turmasList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Nome", value: "nomecompleto" },
          { text: "email", value: "email", sortable: false },
          { text: "data de nascimento", value: "data_nascimento", sortable: false },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },

  mounted() {
    this.listarFormadores();
  },

  watch: {
    carregandoTurmas(value) {
      if (value && this.turmasList.length === 0) {
        this.$loaderService.open("Carregando Turmas");
      } else {
        this.$loaderService.close();
      }
    },

    async search(val) {
      if (val.length > 3) {
        await this.listarFormadores(0, val);
      }
      if (!val) {
        await this.listarFormadores(0);
      }
    },
  },

  methods: {
    async listarFormadores() {
      this.carregandoTurmas = true;
      this.$loaderService.open("Carregando Formadores");
      try {
        const response = await this.$services.diarioFormacaoService.syncAllFormadores();
        this.turmasList = response;
      } catch (error) {
        this.$loaderService.close();
        this.$handleError(error);
      }
      this.carregandoTurmas = false;
      this.$loaderService.close();
    },

    criarFormador() {
      this.$router.push({ name: "diario-formacao.formadores.create" });
    },
    async gerarBoletimGeral(turma) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.boletimGeral(turma.id);
        this.$loaderService.close();
      } catch (error) {
        this.$toast.danger("Ocorreu um erro ao gerar o boletim geral:", error);
      }
    },

    visualizarTurma(turma) {
      if (turma.isInfantil) {
        this.turma_id = turma.id;
        this.dialog = !this.dialog;
        return;
      }
      this.$router.push({ name: "turmas.view", params: { turma_id: turma.id } });
    },

    editarTurma(turma) {
      this.$router.push({ name: "turmas.edit", params: { turma_id: turma.id } });
    },

    dashboard(turma) {
      this.$router.push({ name: "turmas.dashboard", params: { id: turma.id } });
    },

    async deletarFormador(formador) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta formador?",
        confirmationCode: formador.id,
        confirmationMessage: `Por favor, digite <strong>${formador.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando um formador");
              try {
                await this.$services.diarioFormacaoService.deletarFormador(formador);
                this.$toast.success("Formador deletado com sucesso");
                this.listarFormadores();
                // this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },

    recuperarTurma(turma) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta turma?",
        confirmationCode: turma.id,
        confirmationMessage: `Por favor, digite <strong>${turma.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Turma");
              try {
                await this.$services.turmasService.recuperarTurma(turma);
                this.$toast.success("Turma recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>
<style></style>
