<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'diario-formacao.matriculas.index' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Matrícula de Professores
        </h1>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row
          v-show="exibirCampos"
          v-for="matricula of form.matriculas"
          :key="form.matriculas.indexOf(matricula)"
        >
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Professor" rules="required" v-slot="{ errors }">
              <e-label>Professor</e-label>
              <e-autocomplete
                @change="verifyExistMatricula(matricula.instrutor_id, matricula.turma.id)"
                :items="professoresList"
                :return-object="false"
                :disabled="editing"
                :item-value="(aluno) => aluno.id"
                :item-text="(aluno) => aluno.nome"
                :loading="carregandoProfessores"
                :readonly="editing"
                :error-messages="errors"
                v-model="matricula.instrutor_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Turma</e-label>
            <ValidationProvider name="Turma" rules="required" v-slot="{ errors }">
              <e-autocomplete
                :items="turmasListLocal"
                :return-object="true"
                :item-text="(turma) => turma.descricao"
                :loading="carregandoTurmasLocal"
                :error-messages="errors"
                v-model="matricula.turma"
                @change="(turma) => changeTurma(matricula, turma)"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="1">
            <v-btn
              v-if="form.matriculas.length > 1"
              class="mt-8"
              color="error"
              @click="
                () =>
                  (form.matriculas = form.matriculas.filter(
                    (matriculaObj) => matriculaObj !== matricula
                  ))
              "
              small
              ><v-icon small>fa-trash</v-icon></v-btn
            >
          </v-col>
          <v-alert v-if="existeDuplicidade === matricula.instrutor_id" dense outlined type="error">
            Ops! esse professor já tem uma matrícula nessa turma, quando enviar o sistema não vai
            permitir matricula-lo.
          </v-alert>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex">
              <v-btn
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn class="ml-3" v-if="!editing" color="secondary" @click="adicionarMatricula">
                Adicionar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>

    <e-sheet-aviso-matricula-existente
      :sheet="sheet"
      :matricula="matricula"
      @changeSheet="sheet = $event"
    ></e-sheet-aviso-matricula-existente>
  </main-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ESheetAvisoMatriculaExistente from "../../components/Matriculas/ESheetAvisoMatriculaExistente.vue";
import ELabel from "../../components/ELabel.vue";
import Aluno from "../../Models/Aluno";

export default {
  components: {
    ELabel,
    ESheetAvisoMatriculaExistente,
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("Matriculas", ["matriculas", "carregandoMatriculas"]),
    // ...mapGetters("Alunos", ["professoresList", "carregandoProfessores"]),
    ...mapGetters("Turmas", ["turmasList", "carregandoTurmas"]),
  },

  async mounted() {
    this.loadData();
    // this.listarMatriculas();
  },

  data() {
    return {
      existeDuplicidade: false,
      exibirCampos: true,
      disabledBtn: true,
      submittingForm: false,
      series: [],
      form: { matriculas: [{}] },

      carregandoProfessores: false,
      professoresList: [],

      carregandoTurmasLocal: false,
      turmasListLocal: [{}],

      carregandoMatriculasLocal: false,
      matriculasLocal: [{}],
      matriculasListLocal: [{}],

      disabled: true,
      sheet: false,
      matricula: {},
    };
  },
  methods: {
    // ...mapActions("Alunos", ["loadProfessores"]),
    ...mapActions("Turmas", ["loadTurmas"]),

    verifyExistMatricula(instrutorId, turmaId) {
      if (instrutorId && turmaId) {
        this.verificarDuplicidade(instrutorId, turmaId);
      }
    },

    async verificarDuplicidade(instrutorId, turmaId) {
      this.existeDuplicidade = false;
      const result = await this.$services.diarioFormacaoService.verifyMatriculas(
        instrutorId,
        turmaId
      );
      if (result === 1) {
        this.existeDuplicidade = instrutorId;
      }
    },

    changeTurma(matricula, turma) {
      matricula.turma_formacao_id = turma.id;
      this.verifyExistMatricula(matricula.instrutor_id, turma.id);
    },
    async listarMatriculas() {
      this.carregandoMatriculasLocal = true;
      try {
        const response = await this.$services.matriculasService.all();
        this.matriculasListLocal = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoMatriculasLocal = false;
    },

    async loadProfessores() {
      this.carregandoProfessores = true;
      try {
        this.professoresList = await this.$services.diarioFormacaoService.syncAllProfessores();
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoProfessores = false;
    },

    async loadTurmas() {
      this.carregandoTurmasLocal = true;
      try {
        const response = await this.$services.diarioFormacaoService.syncAllTurmas();
        this.turmasListLocal = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoTurmasLocal = false;
    },

    adicionarMatricula() {
      this.form.matriculas.push({});
    },

    async submitForm() {
      this.exibirCampos = false;
      this.submittingForm = true;
      try {
        await this.$services.diarioFormacaoService.matricular(this.form);

        this.$toast.success("Matricula Atualizada com sucesso");
        this.$router.push({ name: "diario-formacao.turmas.index" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    async loadData() {
      try {
        if (this.professoresList.length === 0 || this.turmasListLocal.length === 0) {
          this.$loaderService.open("Carregando dados");
        }
        await this.loadFormData();
        await Promise.all([this.loadTurmas()]);
        this.$loaderService.close();
        this.$forceUpdate();
        this.loadProfessores();
        // this.loadFormData();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async loadFormData() {
      if (!this.matriculasLocal || !this.editing) {
        this.form = { matriculas: [{}] };
        return;
      }
      const { matricula_id } = this.$route.params;
      const matricula = await this.$services.matriculasService.pegarMatricula(matricula_id);
      if (matricula) this.form = { matriculas: [matricula] };
    },
  },
};
</script>

<style></style>
