<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.push({ name: 'saec' })">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Saec
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="pt-0 pb-0">
        <v-select
          label="Escolha a edição da Saec"
          :items="edicoesSaecList"
          variant="solo-filled"
          :item-value="(item) => item.id"
          :item-text="(item) => item.descricao"
          v-model="edicaoSaecSelecionada_id"
          solo
          @change="alterarEdicaoSaec(edicaoSaecSelecionada_id)"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-alert border="left" elevation="1" color="blue" type="success" prominent shaped text>
          {{ franquia.descricao }}
        </v-alert>
      </v-col>
      <v-col cols="11" class="pt-0 pb-0">
        <v-row v-for="(turma, index) of turmas" :key="index">
          <v-col
            v-if="edicaoSaec?.prova.filter((prova) => prova.serie_id === turma.serie_id).length"
          >
            <v-alert border="left" elevation="1" color="blue" prominent shaped text dense>
              Lançar notas:
              <v-btn color="primary" block class="" small @click="lancarNotaDaTurma(turma.id)">
                <v-icon class="px-2"> mdi-book-edit-outline </v-icon>
                {{ turma?.descricao }}
              </v-btn>
              <v-btn
                class="my-2"
                small
                block
                color="green white--text"
                @click="lancarQuestoesSaec(turma.serie.id)"
              >
                <v-icon class="px-2"> mdi-cog-outline </v-icon>
                Gerenciar Questões
              </v-btn>
            </v-alert>
          </v-col>
          <v-col v-else>
            <v-alert border="left" elevation="1" color="red" prominent shaped text dense>
              A turma <strong>{{ turma.descricao }} </strong> da série
              <strong> {{ turma.serie?.descricao }}</strong> ainda não tem questoes relacionadas ela
              nesta edição.
              <v-btn
                class="my-2"
                block
                color="red white--text"
                @click="lancarQuestoesSaec(turma.serie.id)"
              >
                Criar agora
                <v-icon> mdi-alert </v-icon>
              </v-btn>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-btn block color="primary" @click="baixarRelatorio(franquia_id)">
          Baixar Relatório
        </v-btn>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      franquia_id: "",
      franquia: [],
      franquiasList: [],
      edicaoSaec: [],
      edicoesSaecList: [],
      edicaoSaecSelecionada_id: null,
      edicao_id: null,
      turmasInfantil: [],
      turmasFundamental: [],
      turmas: [],
    };
  },
  async mounted() {
    this.franquia_id = this.$route.params.franquia_id;
    this.edicao_id = this.$route.params.edicao_id;
    await this.loadData();
  },
  watch: {
    async edicaoSaecSelecionada_id() {
      this.edicao_id = this.edicaoSaecSelecionada_id;
      await this.buildData();
    },
  },
  methods: {
    async loadData() {
      this.franquia = await this.$services.franquiasService.getFranquia(this.franquia_id);
      this.edicoesSaecList = await this.$services.saecService.getEdicoesSaec();
      await this.buildData();
    },
    async buildData() {
      this.$loaderService.open("Carregando dados");
      this.edicaoSaec = await this.$services.saecService.getUmaEdicaoSaec(this.edicao_id);
      this.edicaoSaecSelecionada_id = parseInt(this.edicaoSaec.id, 10);
      this.franquiasList = await this.$services.saecService.getFranquiasEdicaoSaec(
        this.edicaoSaecSelecionada_id
      );
      this.turmasFundamental = await this.$services.saecService.getTurmasFundamental(
        this.franquia_id
      );
      this.turmasInfantil = await this.$services.saecService.getTurmasInfantil(this.franquia_id);

      this.turmasFundamental = this.turmasFundamental.sort(this.alpabethicalOrder);
      this.turmasInfantil = this.turmasInfantil.sort(this.alpabethicalOrder);
      this.turmas = [...this.turmasFundamental, ...this.turmasInfantil];
      this.$loaderService.close();
    },
    lancarNotasSaec() {
      this.$router.push({
        name: "saec.lancarNotas",
        params: {
          franquia_id: this.franquia_id,
          edicao_id: this.edicaoSaecSelecionada_id,
        },
      });
    },
    lancarNotaDaTurma(turma_id) {
      this.$router.push({
        name: "saec.lancarNotasDaTurma",
        params: {
          franquia_id: this.franquia_id,
          edicao_id: this.edicaoSaec.id,
          turma_id,
        },
      });
    },
    lancarQuestoesSaec(serie_id) {
      this.$router.push({
        name: "saec.lancarQuestoesSaec",
        params: {
          edicao_id: this.edicaoSaec.id,
          serie_id,
        },
      });
    },
    baixarRelatorio(franquia_id) {
      // alert("relatorio baixado com sucesso!");
    },
    async alterarEdicaoSaec(edicao_id) {
      this.franquiasList = await this.$services.saecService.getFranquiasEdicaoSaec(edicao_id);
    },
    alpabethicalOrder(a, b) {
      return a.descricao.localeCompare(b.descricao);
    },
  },
};
</script>

<style></style>
