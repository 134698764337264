<template>
  <impressao-template>
    <v-row>
      <v-col cols="6">
        <v-card class="mx-auto" outlined>
          <apexchart
            v-if="show"
            height="300px"
            width="50%"
            :options="options"
            :series="series"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mx-auto" outlined>
          <v-card-text>
            <h3>
              Total Geral de Faltas nessa Turma:
              {{ totalGeralFaltas.reduce((total, valor) => total + valor, 0) }}
            </h3>
            <ul v-for="(total, i) in totalGeralFaltas" :key="i">
              <li class="mt-5 mb-5">
                <h3>{{ options.labels[i] }}: {{ total }}</h3>
              </li>
              <v-simple-table style="border: 0.1rem solid #f1f1f1" dense>
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #f1f1f1">
                      <th class="text-left">Professor</th>
                      <th class="text-left">Encontro</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in allFaltasList[i]" :key="item.id">
                      <td v-if="item.professor?.nome">{{ item.professor?.nome }}</td>
                      <td v-if="item.encontro?.referencia">{{ item.encontro?.referencia }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </ul>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </impressao-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    vinculoId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      allFaltasList: [],
      totalGeralFaltas: 0,
      dataAll: null,
      referencias: [],
      porcentagens: [],
      series: [],
      show: false,
      options: {
        chart: {
          foreColor: "#263238",
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 4],
        },
        plotOptions: {
          bar: {
            // horizontal: true,
            columnWidth: "35%",
            endingShape: "rounded",
          },
        },
        colors: ["#0d6efd", "#212529"],
        title: {
          text: "Grafico de presenças nos encontros",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [],
        xaxis: {},
      },
    };
  },
  mounted() {
    this.getFrequenciaRelatorioDiario();
  },
  methods: {
    async getFrequenciaRelatorioDiario() {
      const dados = await this.$services.diarioFormacaoService.getFrequenciaRelatorioDiario(
        this.$route.params.vinculo
      );
      this.dataAll = dados.dataAll;
      this.allFaltasList = dados.faltasList;
      this.options.labels = dados.referencias;
      this.options.title.text = dados.dataAll.turma_formacao.descricao;
      this.totalGeralFaltas = dados.totalGeralFaltas;
      this.series = [
        {
          name: "Total de Presentes (%)",
          type: "column",
          data: dados.porcentagens,
        },
      ];
      this.show = true;
    },
  },
};
</script>
