<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'planosEnsino' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano de Ensino
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0">
            <ValidationProvider name="Modelo" rules="required" v-slot="{ errors }">
              <e-label>Modelo do Plano:</e-label>
              <v-select
                :items="modelos"
                :error-messages="errors"
                label="Selecione um Modelo"
                v-model="formPlano.modelo_plano_ensino_id"
                :item-text="(componente) => componente.descricao"
                :item-value="(componente) => componente.id"
                @change="getEstrutura(formPlano.modelo_plano_ensino_id)"
                outlined
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0">
            <ValidationProvider name="Titulo" rules="required" v-slot="{ errors }">
              <e-label>Título do Plano:</e-label>
              <v-text-field
                :error-messages="errors"
                v-model="formPlano.titulo"
                outlined
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0">
            <ValidationProvider name="Disciplina" rules="required" v-slot="{ errors }">
              <e-label>COMPONENTES CURRICULARES:</e-label>
              <v-select
                :items="disciplinas"
                :error-messages="errors"
                label="Selecione um Area"
                v-model="formPlano.disciplina_id"
                :item-text="(componente) => componente.descricao"
                :item-value="(componente) => componente.id"
                outlined
                @change="habilidadesDisci()"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0">
            <ValidationProvider name="Mes" rules="required" v-slot="{ errors }">
              <e-label>Avaliação:</e-label>
              <v-select
                :items="$constants.avaliacoes"
                :error-messages="errors"
                label="Selecione uma avaliação"
                v-model="formPlano.mes"
                :item-text="(componente) => componente.descricao"
                :item-value="(componente) => componente.id"
                outlined
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0">
            <ValidationProvider rules="required" name="Descrição" v-slot="{ errors }">
              <e-label>ÁREA DO CONHECIMENTO:</e-label>
              <v-select
                :items="$constants.areaConhecimento"
                :error-messages="errors"
                label="Selecione um Area"
                v-model="formPlano.area_conhecimento"
                :item-text="(componente) => componente"
                :item-value="(componente) => componente"
                outlined
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <e-label>ANOS:</e-label>
              <v-select
                :items="series"
                :error-messages="errors"
                label="Selecione as séries"
                v-model="formPlano.series"
                :item-text="(componente) => componente.descricao"
                :item-value="(componente) => componente.id"
                outlined
                chips
                multiple
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-card
          class="animate__animated animate__zoomIn row mb-5 ml-1 mr-1"
          style="animation-duration: 0.5s"
          v-for="sub of subPlanos"
          :key="subPlanos.indexOf(sub)"
        >
          <v-col class="pt-0 pb-0 mb-3 mt-3" cols="12">
            <h5>Linha {{ subPlanos.indexOf(sub) + 1 }}</h5>
          </v-col>
          <v-col v-for="est of estrutura" :key="estrutura.indexOf(est)" class="pt-0 pb-0" cols="6">
            <ValidationProvider
              v-if="est.tipo_campo === 'Seleção'"
              :name="est.nome_campo"
              rules=""
              v-slot="{ errors }"
            >
              <div v-if="editing">
                <li v-for="(ss, index) of sub[estrutura.indexOf(est)]" :key="index">
                  {{ ss }}
                </li>
              </div>
              <template v-if="editing">
                <e-label>{{ est.nome_campo }}</e-label>
                <v-select
                  :items="est.opcoes_campo"
                  :error-messages="errors"
                  label="Clique aqui para alterar as opções"
                  @change="
                    updateResposta(
                      subPlanos.indexOf(sub),
                      estrutura.indexOf(est),
                      est.nome_campo,
                      $event
                    )
                  "
                  :item-text="(componente) => componente"
                  :item-value="(componente) => componente"
                  outlined
                  chips
                  multiple
                ></v-select>
              </template>
              <template v-else>
                <div v-if="est.condicao_ativado === true">
                  <e-label>{{ est.nome_campo }}</e-label>
                  <v-select
                    :items="habilidadesBncc"
                    :error-messages="errors"
                    label="Selecione uma opcao"
                    @change="
                      updateResposta(
                        subPlanos.indexOf(sub),
                        estrutura.indexOf(est),
                        est.nome_campo,
                        $event
                      )
                    "
                    :item-text="(componente) => componente.apelido"
                    :item-value="(componente) => componente.apelido"
                    outlined
                    chips
                    multiple
                  ></v-select>
                </div>
              </template>
            </ValidationProvider>

            <ValidationProvider v-else :name="est.nome_campo" rules="" v-slot="{ errors }">
              <e-label>{{ est.nome_campo }}</e-label>
              <v-textarea
                v-if="editing"
                @change="
                  updateResposta(
                    subPlanos.indexOf(sub),
                    estrutura.indexOf(est),
                    est.nome_campo,
                    $event
                  )
                "
                outlined
                :label="est.nome_campo"
                name="input-7-1"
                :error-messages="errors"
                :value="sub[estrutura.indexOf(est)]"
              ></v-textarea>
              <v-textarea
                v-else
                @change="
                  updateResposta(
                    subPlanos.indexOf(sub),
                    estrutura.indexOf(est),
                    est.nome_campo,
                    $event
                  )
                "
                outlined
                :label="est.nome_campo"
                name="input-7-1"
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-card>

        <v-btn
          class="mb-5 mt-5"
          color="secondary"
          @click="adicionarSubPlano"
          :disabled="submittingForm"
        >
          Adicionar Linha
        </v-btn>

        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<style></style>

<script>
import { mapActions, mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formPlano() {
      if (!this.planos || !this.editing) return {};
      const { plano_id } = this.$route.params;
      const plano = this.planos.filter((cir) => cir.id === parseInt(plano_id, 10)).shift();
      return { ...plano };
    },
  },
  mounted() {
    this.loadModelos();
    this.loadPlanos();
    this.loadDisciplinas();
    this.loadSeries();
  },
  watch: {
    formPlano(val) {
      if (this.editing) {
        this.popularCampos(val.valores);
      }
    },
    subPlanos(val) {
      if (this.editing) {
        const modelo = this.modelos.filter(
          (modelo) => modelo.id === this.formPlano.modelo_plano_ensino_id
        );
        this.getEstrutura(modelo[0].id);
      }
    },
  },
  data() {
    return {
      subPlanos: [{}],
      modelos: [],
      disciplinas: null,
      submittingForm: false,
      estrutura: null,
      opcoes: null,
      respostas: [],
      series: [],
      planos: [],
      habilidadesBncc: [],
    };
  },
  methods: {
    async getHabilidadesBncc(idDisciplina) {
      const data = await this.$services.planosEnsinoService.filterHabilidades(idDisciplina);
      this.habilidadesBncc = data;
    },
    habilidadesDisci() {
      // console.log(this.formPlano.disciplina_id);
      this.getHabilidadesBncc(this.formPlano.disciplina_id);
    },
    exibir(componente) {
      let valorDoCampoASerVerificado = null;
      let operador = null;

      switch (componente.condicao_campo) {
        case "COMPONENTES CURRICULARES":
          valorDoCampoASerVerificado = this.formPlano.disciplina_id;
          operador = componente.condicao_operacao;
          if (operador === "Igual a") {
            if (valorDoCampoASerVerificado === this.formPlano.disciplina_id) {
              return true;
            }
          }
          break;

        default:
          break;
      }

      return false;
    },
    popularCampos(data) {
      this.subPlanos = JSON.parse(data);
    },
    async loadSeries() {
      try {
        const data = await this.$services.seriesService.syncAll();
        this.series = data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    updateResposta(linha, indexCampo, nomeCampo, valor) {
      const respostaObj = {
        linha,
        indexCampo,
        campo: nomeCampo,
        resposta: valor,
      };

      this.respostas.push(respostaObj);
      this.subPlanos[linha][indexCampo] = valor;
    },

    getEstrutura(componentId) {
      const modeloAtual = this.modelos.filter((modelo) => modelo.id === componentId);
      this.estrutura = JSON.parse(modeloAtual[0].estrutura);
    },

    adicionarSubPlano() {
      const ultimoSubPlano = this.subPlanos[this.subPlanos.length - 1];
      this.subPlanos.push({ ...ultimoSubPlano });
    },

    async loadModelos() {
      try {
        const data = await this.$services.modelosPlanoEnsinoService.syncAll();
        this.modelos = data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async loadPlanos() {
      try {
        const data = await this.$services.planosEnsinoService.syncAll();
        this.planos = data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async loadDisciplinas() {
      try {
        const data = await this.$services.sistemaBNCCService.listarTodos();
        this.disciplinas = data.sistema.filter((comp) => comp.parent_id === 89);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async submitForm() {
      this.submittingForm = true;
      this.formPlano.valores = JSON.stringify(this.subPlanos);
      try {
        if (this.editing) {
          await this.$services.planosEnsinoService.editar(this.formPlano);
        } else {
          await this.$services.planosEnsinoService.criar(this.formPlano);
        }
        this.$toast.success("Plano Atualizada com sucesso");
        this.$router.push({ name: "planosEnsino" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
  },
};
</script>

<style></style>
