import Axios from "@/plugins/Axios";
import Franquia from "@/Models/Franquia";

export class SaecService {
  async getEdicoesSaec() {
    const response = await Axios().get(`saec/edicoes`);
    return response.data;
  }

  async getFranquiasEdicaoSaec(edicao_id) {
    const response = await Axios().get(`saec/${edicao_id}/franquias`);
    const franquias = response.data.map((franquia) => new Franquia(franquia));
    return franquias;
  }

  async getUmaEdicaoSaec(edicao_id) {
    const response = await Axios().get(`saec/edicao/${edicao_id}`);
    return response.data;
  }

  async getQuestoesDaProva(edicao_id, serie_id) {
    const response = await Axios().get(`saec/get-questoes/${edicao_id}/${serie_id}`);
    return response.data;
  }

  async criarEdicaoSaec(data) {
    const response = await Axios().post(`saec/edicao/criar`, data);
    return response.data;
  }

  async editarEdicaoSaec(edicao_id, data) {
    const response = await Axios().put(`saec/edicao/atualizar/${edicao_id}`, data);
    return response.data;
  }

  async getTurmasFundamental(franquia_id) {
    const response = await Axios().get(`saec/series-fundamental/${franquia_id}`);
    return response.data;
  }

  async getTurmasInfantil(franquia_id) {
    const response = await Axios().get(`saec/series-infantil/${franquia_id}`);
    return response.data;
  }

  async getTurma(turma_id) {
    const response = await Axios().get(`saec/get-turma/${turma_id}`);
    return response.data;
  }

  async getSeries(franquia_id) {
    const response = await Axios().get(`saec/series/${franquia_id}`);
    return response.data;
  }

  async getUmaSerie(serie_id) {
    const response = await Axios().get(`saec/get-serie/${serie_id}`);
    return response.data;
  }

  async getQuestoesDaSerie(edicao_id, serie_id) {
    const response = await Axios().get(`saec/get-questoes/${edicao_id}/${serie_id}`);
    return response.data;
  }

  async getQuestao(questao_id) {
    const response = await Axios().get(`saec/get-questao/${questao_id}`);
    return response.data;
  }

  async criarQuestao(form) {
    const response = await Axios().post(`saec/criar-questao`, form);
    return response.data;
  }

  async atualizarQuestao(questao_id, form) {
    const response = await Axios().post(`saec/atualizar-questao/${questao_id}`, form);
    return response.data;
  }
}
export default new SaecService();
