import Axios from "@/plugins/Axios";

export class HorariosService {
  async syncAll() {
    const response = await Axios().get(`listar-horarios`);
    return response.data;
  }

  async getHorariosDoTurnoDaTurma(turma_id) {
    const response = await Axios().get(`horarios-do-turno-da-turma/${turma_id}`);
    return response.data;
  }

  async getHorariosDaFranquia(franquia_id) {
    const response = await Axios().get(`listar-horarios-da-franquia/${franquia_id}`);
    return response.data;
  }
}
export default new HorariosService();
