import Autorizacao from "@/Models/Autorizacao";
import Axios from "@/plugins/Axios";
import store from "@/store";

export class AutorizacoesService {
  async verificarPedidosDeferidos(etapa, relacao) {
    const currentUser = store.getters["Auth/user"];
    const response = await Axios().get(
      `verificar-pedidos-deferidos?user_id=${currentUser.id}&etapa_id=${etapa}&relacao_id=${relacao}`
    );
    // const usuarios = response.data.data.map((usuario) => new Usuario(usuario));
    return response;
  }

  async getUsuarios(pageNumber, query = "") {
    const currentUser = store.getters["Auth/user"];
    const response = await Axios().get(
      `get-usuarios-autorizacao?configuracao_id=${currentUser.configuracao_id}`
    );
    // const usuarios = response.data.data.map((usuario) => new Usuario(usuario));
    return response;
  }

  async syncAll(tipo) {
    const currentUser = store.getters["Auth/user"];
    let url = "autorizacoes";

    if (tipo === "solicitante") {
      url = `autorizacoes?userSolicitante=${currentUser.id}`;
    }
    if (tipo === "aprovador") {
      url = `autorizacoes?userAprovador=${currentUser.id}`;
    }
    if (tipo === "admin") {
      url = `autorizacoes?admin=${currentUser.id}`;
    }

    const response = await Axios().get(url);
    const etapas = response.data.map((etapa) => new Autorizacao(etapa));
    // store.commit("Autorizacoes/setAutorizacoes", etapas);
    return etapas;
  }

  async criarAutorizacao(etapa) {
    const response = await Axios().post("autorizacoes", etapa);
    const novoEtapa = response.data;
    return novoEtapa;
  }

  async atualizarAutorizacao(dados) {
    const response = await Axios().put(`autorizacoes/${dados.id}`, {
      observacoes: dados.observacoes,
      data_expiracao: dados.data_expiracao,
    });
    const etapa = response.data;
    return etapa;
  }

  async atualizarSituacao(autorizacao, novaSituacao) {
    const response = await Axios().put(`autorizacoes/${autorizacao.id}`, {
      ...autorizacao,
      status: novaSituacao,
    });
    const autAtualizada = response.data;
    return new Autorizacao(autAtualizada);
  }

  async deletarEtapa(circuito) {
    const response = await Axios().delete(`autorizacoes/${circuito.id}`);
    const etapaDeletada = response.data;
    store.commit("Autorizacoes/deletarAutorizacao", circuito.id);
    return etapaDeletada;
  }
}
export default new AutorizacoesService();
