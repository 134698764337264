<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$services.authService.logout();
    if (this.$route.name !== "login") {
      this.$router.push({
        name: "login",
        query: {
          redirect: this.$route.query.redirect,
          name: this.$route.query.name,
        },
      });
    }
  },
};
</script>

<style></style>
