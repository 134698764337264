import ModelosPlanoEnsinoIndexPage from "@/Pages/ModelosPlanoEnsino/index.vue";
import NewEditModelosPlanoEnsinoPage from "@/Pages/ModelosPlanoEnsino/NewEditModelosPlanoEnsinoPage.vue";

export default [
  {
    path: "/modelos-plano-ensino/",
    name: "modelosplanoEnsino",
    component: ModelosPlanoEnsinoIndexPage,
  },
  {
    path: "/modelos-plano-ensino/create",
    name: "modelosplanoEnsino.create",
    component: NewEditModelosPlanoEnsinoPage,
    props: { editing: false },
  },
  {
    path: "/modelos-plano-ensino/edit/:plano_id",
    name: "modelosplanoEnsino.edit",
    component: NewEditModelosPlanoEnsinoPage,
    props: { editing: true },
  },
];
