<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'saec.lancarQuestoesSaec',
                  params: { franquia_id, edicao_id },
                })
            "
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Lançar Questões Para Prova Saec
        </h1>
      </v-col>
      <v-col cols="12">
        <v-alert
          border="left"
          type="success"
          color="green"
          class="d-flex flex-column ps-5 py-5"
          prominent
          shaped
          text
          dense
        >
          <div class="text-h5">Resumo</div>
          <div class="text-subtitle-2">
            Existe(m) {{ questoesList.length }} quetão(ões) lançada(s) para a serie
            {{ serie?.descricao }}, nesta edição.
          </div>
          <div class="text-subtitle-2">{{ edicaoSaec?.descricao }}</div>
          <div class="text-subtitle-2">{{ serie?.descricao }}</div>
        </v-alert>
        <v-row class="pa-2">
          <v-col class="py-0" cols="4" v-for="(item, index) in resumo" :key="index">
            <v-sheet
              rounded
              color="green white--text"
              class="d-flex justify-space-between flex-wrap ma-2 px-5 py-5 text-subtitle-2"
            >
              {{ index }} <br />
              Possui
              {{ item.length }}
              questões lançadas
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pb-5">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="cadastrarQuestao" class="mr-2">Cadastrar Questão</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
    </v-row>

    <v-data-table
      :headers="table.headers"
      :items="questoesList"
      :items-per-page="10"
      :search="search"
      class="elevation-1"
    >
      <!-- :loading="carregandoDados" -->
      <!-- :custom-filter="dataTableFilter" -->
      <template v-slot:body="{ items }">
        <tbody name="scroll-x-transition" is="transition-group" duration="150">
          <tr
            color="primary"
            :class="questao.estaDeletado ? ['deleted'] : []"
            v-for="questao of items"
            :key="questao.id"
          >
            <td>{{ questao.id }}</td>
            <td>{{ questao.codigo }}</td>
            <td>{{ questao.habilidade.substring(0, 150).concat("...") }}</td>
            <td>{{ questao.disciplina?.descricao }}</td>
            <td>
              <v-tooltip v-if="!questao.estaDeletado" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="blue-grey lighten-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => editarQuestao(questao.id)"
                  >
                    <v-icon small>fa fa-edit </v-icon>
                  </v-btn>
                </template>
                <span>Editar Questão</span>
              </v-tooltip>
              <v-tooltip v-if="!questao.estaDeletado" color="error" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => deletarQuestao(questao.id)"
                  >
                    <v-icon small>fa fa-trash </v-icon>
                  </v-btn>
                </template>
                <span>Apagar questao</span>
              </v-tooltip>
              <!-- <v-tooltip v-if="questao.estaDeletado" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => recuperarquestao(questao)"
                  >
                    <v-icon small>fa fa-sync </v-icon>
                  </v-btn>
                </template>
                <span>Recuperar questao</span>
              </v-tooltip> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código", value: "codigo" },
          { text: "Habilidade", value: "habilidade" },
          { text: "Disciplina", value: "disciplina" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
      dataTableFilter: "",
      search: "",
      franquia_id: "",
      franquia: null,
      edicaoSaec: null,
      edicao_id: null,
      serie_id: null,
      serie: null,
      questoesList: [],
      resumo: [],
    };
  },
  async mounted() {
    this.franquia_id = this.$route.params.franquia_id;
    this.edicao_id = this.$route.params.edicao_id;
    this.serie_id = this.$route.params.serie_id;
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.edicaoSaec = await this.$services.saecService.getUmaEdicaoSaec(this.edicao_id);
      this.franquia = await this.$services.franquiasService.getFranquia(this.franquia_id);
      this.serie = await this.$services.saecService.getUmaSerie(this.serie_id);
      this.questoesList = await this.$services.saecService.getQuestoesDaSerie(
        this.edicao_id,
        this.serie_id
      );
      [this.questoesList, this.resumo] = this.questoesList;
      // console.log(this.questoesList);
    },
    indexQuestoesSaecDaSerie(serie_id) {
      this.$router.push({
        name: "saec.lancarQuestoesSaec",
        params: {
          edicao_id: this.edicaoSaec.id,
          serie_id,
        },
      });
    },
    cadastrarQuestao() {
      this.$router.push({
        name: "saec.newEditQuestaoSaec",
        params: {
          franquia: this.franquia_id,
          edicao_id: this.edicaoSaec.id,
          serie_id: this.serie_id,
          questao_id: "new",
        },
      });
    },
    editarQuestao(questao_id) {
      this.$router.push({
        name: "saec.newEditQuestaoSaec",
        params: {
          franquia: this.franquia_id,
          edicao_id: this.edicaoSaec.id,
          serie_id: this.serie_id,
          questao_id,
        },
      });
    },
  },
};
</script>

<style></style>
