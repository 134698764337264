import Etapa from "@/Models/Etapa";
import Axios from "@/plugins/Axios";
import store from "@/store";

export class DiarioFormacaoService {
  async encontros(vinculoId) {
    const response = await Axios().get(`encontros?vinculo_id=${vinculoId}`);
    const encontros = response.data;
    return encontros;
  }

  async getFrequenciaRelatorioDiario(idRelacao) {
    const response = await Axios().get(`diarioFormador/relatorio-frequencia-vinculo/${idRelacao}`);
    const dados = response.data;
    return dados;
  }

  async getFrequenciaRelatorioGeral(referencia, componente_curricular, tipoFalta) {
    const response = await Axios().get(
      `diarioFormador/relatorio-frequencia-geral?referencia=${referencia}&componente_curricular=${componente_curricular}&tipo_falta=${tipoFalta}`
    );
    const dados = response.data;
    return dados;
  }

  async baixarDocJustificativa(aulaId, matriculaId) {
    const response = await Axios()
      .get(`encontros/justificativa/file/baixar/${aulaId}/${matriculaId}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `justificativa_${matriculaId}.pdf`;
        link.click();
      });
    return response;
  }

  async baixarListaMatriculas(turmaId) {
    const response = await Axios()
      .get(`relatorios/lista-matriculas/${turmaId}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `lista_matriculas_${turmaId}.pdf`;
        link.click();
      });
    return response;
  }

  async baixarDocAGenda(aulaId) {
    const response = await Axios()
      .get(`encontros/agenda/baixar/${aulaId}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `agenda_${aulaId}.pdf`;
        link.click();
      });
    return response;
  }

  async baixarDocPpt(aulaId) {
    const response = await Axios()
      .get(`encontros/ppt/baixar/${aulaId}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `ppt_${aulaId}.pdf`;
        link.click();
      });
    return response;
  }

  async criarEncontro(encontro) {
    const formData = new FormData();
    formData.append("horario_inicio", encontro.horario_inicio);
    formData.append("horario_final", encontro.horario_final);
    formData.append("data", encontro.data);
    formData.append("referencia", encontro.referencia);
    formData.append("projeto", encontro.projeto);
    formData.append("componente_curricular", encontro.componente_curricular);
    formData.append("ano_etapa_modalidade", encontro.ano_etapa_modalidade);
    formData.append("tema", encontro.tema);
    formData.append("objetivo", encontro.objetivo);
    formData.append("agenda", encontro.agenda ?? null);
    formData.append("ppt", encontro.ppt ?? null);
    formData.append("diario_formacao_id", encontro.diario_formacao_id);
    formData.append("resultado_avaliacao_cursistas", encontro.resultado_avaliacao_cursistas);

    Axios().interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });

    const response = await Axios().post(`encontros`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  }

  async justificarFalta(
    encontro,
    { observacao = "", file = null, matriculaSelect = null, justificativa_id = null }
  ) {
    const formData = new FormData();
    formData.append("observacao", observacao);
    formData.append("file", file);
    formData.append("matricula_id", matriculaSelect.id);
    formData.append("justificativa_id", justificativa_id);

    Axios().interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });

    const response = await Axios().post(
      `encontros/frequencia/justificativa/${encontro.id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  }

  async registrarPresenca(encontro, { matricula_id, presente }) {
    const response = await Axios().post(`encontros/frequencia/${encontro.id}`, {
      matricula_id,
      presente,
    });
    return response.data;
  }

  async getEncontroComRelacoes(aulaId) {
    const response = await Axios().get(`encontros/${aulaId}`);
    const encontros = response.data;
    return encontros;
  }

  async vinculos() {
    const response = await Axios().get("diarioFormador?formador=true");
    const turmas = response.data;
    return turmas;
  }

  async vincular(form) {
    form.vinculos = form.matriculas.map((matricula) => ({
      formador_id: matricula.formador_id,
      turma_formacao_id: matricula.turma_formacao_id,
    }));
    form.matriculas = [];
    const response = await Axios().post("diarioFormador", form);
    const novaMatricula = response.data;
    return novaMatricula;
  }

  async syncAllTurmas() {
    const response = await Axios().get("turmasFormacao");
    const turmas = response.data;
    return turmas;
  }

  async syncAllMatriculas() {
    const response = await Axios().get("matriculasFormacao");
    const turmas = response.data;
    return turmas;
  }

  async verifyMatriculas(instrutorId, turmaId) {
    const response = await Axios().get(
      `matriculasFormacao/verificar-existencia/${instrutorId}/${turmaId}`
    );
    const turmas = response.data;
    return turmas;
  }

  async verifyVinculos(formadorId, turmaId) {
    const response = await Axios().get(
      `diarioFormador/verificar-existencia-formador/${formadorId}/${turmaId}`
    );
    const turmas = response.data;
    return turmas;
  }

  async deletarEncontro(encontro) {
    const response = await Axios().delete(`encontros/${encontro.id}`);
    const encontros = response.data;
    return encontros;
  }

  async deletarMatricula(matricula) {
    const response = await Axios().delete(`matriculasFormacao/${matricula.id}`);
    const turmas = response.data;
    return turmas;
  }

  async deletarTurma(turma) {
    const response = await Axios().delete(`turmasFormacao/${turma.id}`);
    const turmas = response.data;
    return turmas;
  }

  async deletarFormador(turma) {
    const response = await Axios().delete(`formadores/${turma.id}`);
    const turmas = response.data;
    return turmas;
  }

  async syncAllFormadores() {
    const response = await Axios().get("formadores");
    const formadores = response.data;
    return formadores;
  }

  async syncAllProfessores() {
    const response = await Axios().get("professores");
    const profess = response.data;
    return profess;
  }

  async criarTurma(turma) {
    const response = await Axios().post("turmasFormacao", turma);
    const novaTurma = response.data;
    return novaTurma;
  }

  async criarFormador(formador) {
    const response = await Axios().post("formadores", formador);
    const novoFormador = response.data;
    return novoFormador;
  }

  async matricular(form) {
    form.matriculas = form.matriculas.map((matricula) => ({
      instrutor_id: matricula.instrutor_id,
      turma_formacao_id: matricula.turma_formacao_id,
    }));
    const response = await Axios().post("matriculasFormacao", form);
    const novaMatricula = response.data;
    return novaMatricula;
  }
}
export default new DiarioFormacaoService();
