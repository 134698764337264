<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <div class="text-h4">Saec {{ user.ano.descricao }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="gerenciarEdicoes" class="mr-2"
            >Gerenciar Edições Saec</v-btn
          >
        </div>
        <v-select
          label="Escolha a edição da Saec"
          :items="edicoesSaecList"
          variant="solo-filled"
          :item-value="(item) => item?.id"
          :item-text="(item) => item.descricao"
          v-model="edicaoSaecSelecionada"
          solo
          @change="alterarEdicaoSaec(edicaoSaecSelecionada)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3" v-for="franquia in franquiasList" :key="franquia?.id">
        <v-sheet :elevation="10" border rounded>
          <v-card
            :title="franquia.descricao"
            :text="franquia.descricao"
            variant="tonal"
            class="d-flex flex-column"
          >
            <v-sheet color="green" class="text-subtitle-1 text-center px-2 py-2">
              <div class="white--text">
                {{ franquia.descricao }}
              </div>
            </v-sheet>
            <v-card-text class="text-center px-2 py-2"> 100% </v-card-text>
            <v-card-actions class="justify-center mt-auto">
              <v-btn @click="franquiaSaec(franquia?.id)" class="primary">Ver mais</v-btn>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      franquiasList: [],
      edicoesSaecList: [],
      edicaoSaecSelecionada: null,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  watch: {
    edicaoSaecSelecionada(value) {
      this.edicaoSaecSelecionada = value;
    },
  },
  methods: {
    async loadData() {
      this.$loaderService.open("Carregando dados");
      this.edicoesSaecList = await this.$services.saecService.getEdicoesSaec();
      if (this.edicoesSaecList.length > 0) {
        this.edicaoSaecSelecionada = parseInt(this.edicoesSaecList[0]?.id, 10);
        this.franquiasList = await this.$services.saecService.getFranquiasEdicaoSaec(
          this.edicoesSaecList[0]?.id
        );
      }
      this.$loaderService.close();
    },
    async alterarEdicaoSaec(edicao_id) {
      this.franquiasList = await this.$services.saecService.getFranquiasEdicaoSaec(edicao_id);
    },
    franquiaSaec(franquia_id) {
      this.$router.push({
        name: "saec.franquia",
        params: {
          franquia_id,
          edicao_id: this.edicaoSaecSelecionada,
        },
      });
    },
    gerenciarEdicoes() {
      this.$router.push({
        name: "saec.listarEdicoes",
      });
    },
  },
};
</script>

<style></style>
