<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Selecione uma Mês </v-card-title>

        <v-select
          :items="meses"
          item-text="descricao"
          item-value="id"
          v-model="mes_id"
          return-object
          label="Selecione um mês"
          solo
          @change="baixar()"
        ></v-select>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    gestaoDeAulasId: {
      type: Number,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    dialog(val) {
      this.$emit("status", val);
    },
    gestaoDeAulasId(val) {
      this.meses = [];
      this.verificarMeses();
    },
  },
  data() {
    return {
      mes_id: null,
      meses: [],
    };
  },
  methods: {
    async verificarMeses() {
      const response = await this.$services.planosEnsinoService.getMeses(this.gestaoDeAulasId);
      response.forEach((element) => {
        const mes = this.$constants.avaliacoes.filter((dado) => dado.id === element);
        this.meses.push(mes[0]);
      });
    },

    async baixar() {
      this.mes_id = this.mes_id.id;
      this.$loaderService.open("Carregando dados...");
      await this.$services.planosEnsinoService.baixarPlano(this.gestaoDeAulasId, this.mes_id);
      this.$loaderService.close();
    },
  },
};
</script>
