import Axios from "@/plugins/Axios";
import store from "@/store";
import SubEtapa from "@/Models/SubEtapa";

export class ModelosPlanoEnsinoService {
  async syncAll() {
    const response = await Axios().get("modelos-plano-ensino");
    const modelos = response.data;
    return modelos;
  }

  async criar(modelo) {
    const response = await Axios().post("modelos-plano-ensino", modelo);
    const novoModelo = response.data;
    return novoModelo;
  }

  async atualizarSubEtapa(circuito) {
    const response = await Axios().put(`modelos-plano-ensino/${circuito.id}`, circuito);
    const etapa = response.data;
    return etapa;
  }

  async deletar(dado) {
    const response = await Axios().delete(`modelos-plano-ensino/${dado.id}`);
    const subEtapaDeletada = response.data;
    return subEtapaDeletada;
  }
}
export default new ModelosPlanoEnsinoService();
