import SaecIndexPage from "@/Pages/Saec/index.vue";
import SaecFranquiaIndex from "@/Pages/Saec/saecFranquia.vue";
import SaecFranquiaLancarNotas from "@/Pages/Saec/saecFranquiaLancarNotas.vue";
import SaecLancarNotasDaTurma from "@/Pages/Saec/saecLancarNotasDaTurma.vue";
import SaecLancarQuestoesSaec from "@/Pages/Saec/saecLancarQuestoesSaec.vue";
import SaecLancarQuestoesSaecDaSerieList from "@/Pages/Saec/saecLancarQuestoesSaecDaSerieList.vue";
import NewEditQuestaoSaec from "@/Pages/Saec/newEditQuestaoSaec.vue";
import NewEditEdicaoSaec from "@/Pages/Saec/newEditEdicaoSaec.vue";
import SaecEdicoesList from "@/Pages/Saec/SaecEdicoesList.vue";

export default [
  {
    path: "/saec",
    name: "saec",
    component: SaecIndexPage,
  },
  {
    path: "/saec/edicoes",
    name: "saec.listarEdicoes",
    component: SaecEdicoesList,
  },
  {
    path: "/saec-franquia/:franquia_id/:edicao_id",
    name: "saec.franquia",
    component: SaecFranquiaIndex,
  },
  {
    path: "/saec/edicoes/:edicao_id/",
    name: "saec.newEditEdicaoSaec",
    component: NewEditEdicaoSaec,
  },
  {
    path: "/saec-franquia/:franquia_id/lancar-notas/:edicao_id",
    name: "saec.lancarNotas",
    component: SaecFranquiaLancarNotas,
  },
  {
    path: "/saec-franquia/:franquia_id/lancar-notas/:edicao_id/:turma_id",
    name: "saec.lancarNotasDaTurma",
    component: SaecLancarNotasDaTurma,
  },
  {
    path: "/saec-franquia/:franquia_id/lancar-questoes/:edicao_id/",
    name: "saec.lancarQuestoesSaec",
    component: SaecLancarQuestoesSaec,
  },
  {
    path: "/saec-franquia/:franquia_id/lancar-questoes/:edicao_id/:serie_id",
    name: "saec.lancarQuestoesSaecDaSerie",
    component: SaecLancarQuestoesSaecDaSerieList,
  },
  {
    path: "/saec-franquia/:franquia_id/lancar-questoes/:edicao_id/:serie_id/:questao_id",
    name: "saec.newEditQuestaoSaec",
    component: NewEditQuestaoSaec,
  },
];
