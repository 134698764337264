<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'acessoResponsavel.home' })"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Inicio
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-col cols="12" class="pt-3 px-0">
          <div class="d-flex mb-2 justify-space-between">
            <v-text-field
              v-model="search"
              class="search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="avisos"
          :loading="carregandoAvisos"
          :items-per-page="10"
          hide-default-footer
          :custom-filter="dataTableFilter"
          :search="search"
          no-data-text="Não foram encontrádos avisos para você."
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr color="primary" v-for="(aviso, index) in items" :key="aviso.id">
                <td>{{ aviso.id }}</td>
                <td>
                  <span class="d-inline-block text-truncate" style="max-width: 150px">
                    {{ aviso.parent.titulo || "- - -" }}
                  </span>
                </td>
                <td>
                  <span class="d-inline-block text-truncate" style="max-width: 150px">
                    {{ aviso.parent.contexto || "- - -" }}
                  </span>
                </td>
                <td class="text-capitalize" :key="index">
                  <v-chip
                    class="ma-2"
                    style="width: 80px; justify-content: center"
                    color="primary"
                    outlined
                    label
                    small
                  >
                    {{ aviso.parent.tipo || "- - -" }}
                  </v-chip>
                </td>
                <td>
                  <v-tooltip color="primary" bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => verAviso(aviso.id, aviso)"
                      >
                        <span class="material-icons"> visibility </span>
                      </v-btn>
                    </template>
                    <span>Visualizar aviso</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="loadData"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog v-model="dialog" width="374">
        <v-card v-if="!aviso" class="mx-auto" max-width="374">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader class="mx-auto" max-width="374" type="card"></v-skeleton-loader>
          </v-sheet>
        </v-card>

        <v-card v-else class="mx-auto" max-width="374">
          <v-img :src="url_image_aviso" height="200px"></v-img>

          <v-card-title> {{ aviso ? aviso.titulo : "- - -" }} </v-card-title>

          <v-card-subtitle> {{ aviso ? aviso.contexto : "- - -" }} </v-card-subtitle>

          <v-card-actions>
            <v-btn @click="dialog = false" color="orange lighten-2" text> Fechar </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      dataTableFilter,
      avisos: [],
      carregandoAvisos: false,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      item: {
        aluno: "nomecompleto",
        turma: "descricao",
        franquia: "descricao",
      },
      tipos: ["alunos", "turmas", "configuracoes"],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Titulo", value: "titulo" },
          { text: "Contexto", value: "contexto" },
          { text: "Tipo", value: "tipo" },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
      aviso: null,
      url_image_aviso: "",
      dialog: false,
    };
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  computed: {
    ...mapGetters("Auth", ["senhaFraca", "user"]),
    id() {
      return this.aviso.id;
    },
  },
  watch: {
    async search(val) {
      if (val.length > 2) {
        await this.loadData(0, val);
      }
      if (!val) {
        await this.loadData(0);
      }
    },
  },
  methods: {
    async loadData(pageNumber, query = null) {
      const response = await this.$services.avisosService.portalAluno(pageNumber, query);
      this.paginate = response;
      this.avisos = response.data;
    },
    async verAviso(aviso_id, aviso) {
      this.dialog = !this.dialog;
      this.url_image_aviso = await this.$services.imageService.downloadImage(
        `notifiq/avisos/download-image/${aviso_id}`
      );
      this.aviso = aviso;
    },
  },
  mounted() {
    this.loadData(this.pageNumber);
  },
};
</script>

<style></style>
