<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="goBack">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Diário do Formador
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="gestoesDeAulasList"
          :loading="carregandoGestoesDeAulas"
          :items-per-page="10"
          no-data-text="Não foram encontrádos vínculos para você, por favor entre em contato com a secretaria/coordenação para que lhe adicionem acesso às turmas e disciplinas"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr color="primary" v-for="gestaoDeAulas of items" :key="gestaoDeAulas.id">
                <td>{{ gestaoDeAulas.id }}</td>
                <td width="18%">
                  {{ gestaoDeAulas.turma_formacao?.descricao }}
                </td>
                <td width="25%">
                  {{ gestaoDeAulas.formador?.nomecompleto }}
                </td>
                <td width="7%">
                  <div class="text-center">
                    <v-menu :close-on-content-click="false" :nudge-width="100" offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small color="blue" class="white--text" v-bind="attrs" v-on="on">
                          AÇÕES
                        </v-btn>
                      </template>

                      <v-card class="mx-auto" max-width="300">
                        <v-list>
                          <v-list-item-group color="primary"> </v-list-item-group>
                          <!-- item -->
                          <v-list-item-group color="primary">
                            <v-list-item @click="() => verEncontros(gestaoDeAulas)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-eye</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Encontros</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <v-list-item-group color="primary">
                            <v-list-item @click="() => verRelatorios(gestaoDeAulas)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-eye</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Relatorios de Frequência</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-select-months-modal
          @dialogChange="dialog = $event"
          :dialog="dialog"
          confirmText="Gerar plano"
          cancelText="Fechar"
        >
        </e-select-months-modal>

        <div v-if="dialogConfirmarAulas">
          <e-dialog-search-aulas-interval-date
            :gestao_selected_to_interval="gestao_selected_to_interval"
            :dialogConfirmarAulas="dialogConfirmarAulas"
            @dialogAulasChange="dialogConfirmarAulas = $event"
          >
          </e-dialog-search-aulas-interval-date>
        </div>
      </v-col>
    </v-row>
    <e-escolha-mes
      @status="abrirModalEscolha = $event"
      :dialog="abrirModalEscolha"
      :gestaoDeAulasId="gestaoDeAulasEscolhida"
    ></e-escolha-mes>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import GestaoDeAula from "@/Models/GestaoDeAula";
import diarioFormacao from "../../router/diario-formacao";

export default {
  data() {
    return {
      gestoesDeAulasList: [],
      carregandoGestoesDeAulas: false,
      dialogConfirmarAulas: false,
      dialog: false,
      gestao_selected: null,
      dialogProgressAulas: false,
      gestao_selected_to_interval: null,
      search: "",
      pageNumber: 0,

      tab: null,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Turma", value: "nomeTurma" },
          { text: "Formador", value: "nomeProfessor" },
          {
            text: "",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
      gestaoDeAulasEscolhida: null,
      abrirModalEscolha: false,
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  async mounted() {
    this.$loaderService.open("Carregando gestoesDeAulas");
    try {
      await this.listarGestoesDeAulas(this.pageNumber);
    } catch (error) {
      this.$handleError(error);
    }
    this.$loaderService.close();
  },
  watch: {
    carregandoGestoesDeAulas(value) {
      if (value && this.gestoesDeAulasList.length === 0) {
        this.$loaderService.open("Carregando gestoesDeAulas");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      this.carregandoAvisos = !this.carregandoAvisos;
      if (val.length > 2) {
        await this.listarGestoesDeAulas(0, val);
      }
      if (!val) {
        await this.listarGestoesDeAulas(0);
      }
      this.carregandoAvisos = !this.carregandoAvisos;
    },
  },

  methods: {
    async listarAulas(gestaoDeAula) {
      this.gestao_selected_to_interval = gestaoDeAula;
      this.dialogConfirmarAulas = true;
    },
    goBack() {
      this.$router.push({ name: "home" });
    },
    routePlanos(route, gestao) {
      this.$router.push({ name: route, params: { gestaoDeAulaId: gestao } });
    },
    async verEncontros(gestaoDeAula) {
      this.$router.push({
        name: "diario-formacao-encontros",
        params: {
          vinculo: gestaoDeAula.id,
        },
      });
    },
    async verRelatorios(gestaoDeAula) {
      this.$router.push({
        name: "diario-formacao.encontros.relatorio.vinculo",
        params: {
          vinculo: gestaoDeAula.id,
        },
      });
    },
    async listarGestoesDeAulas(pageNumber, query = null) {
      this.carregandoGestoesDeAulas = true;
      try {
        const response = await this.$services.diarioFormacaoService.vinculos();
        this.gestoesDeAulasList = response;
      } catch {
        this.carregandoGestoesDeAulas = false;
      }
      this.carregandoGestoesDeAulas = false;
    },
    criarGestaoDeAula() {
      this.$router.push({ name: "diario-formacao.vinculos.create" });
    },
  },
};
</script>
<style></style>
