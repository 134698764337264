<template>
  <impressao-template>
    <v-col cols="12">
      <v-card class="mx-auto" outlined>
        <v-card-text>
          <div class="elemento-para-ocultar">
            <v-row>
              <v-col cols="2">
                <label class="mb-5">Encontro</label>
                <e-autocomplete
                  :items="$constants.referencia_encontros"
                  :return-object="true"
                  v-model="referencia"
                  @change="(referencia) => changeReferencia(referencia)"
                  label="Selecione uma opção"
                  dense
                  solo
                />
              </v-col>
              <v-col cols="2">
                <label class="mb-5">Componente Curricular</label>
                <e-autocomplete
                  :items="$constants.componente_curriculares"
                  :return-object="true"
                  v-model="componente_curricular"
                  :item-text="(comp) => comp.text"
                  :item-value="(comp) => comp.slug"
                  @change="(comp) => changeCompCurricular(comp)"
                  label="Selecione uma opção"
                  dense
                  solo
                />
              </v-col>
              <v-col cols="3">
                <label class="mb-5">Tipo de Falta</label>
                <e-autocomplete
                  :items="tipoFaltas"
                  :return-object="true"
                  v-model="tipo_falta"
                  :item-text="(comp) => comp.text"
                  :item-value="(comp) => comp.id"
                  @change="(comp) => changeTipoFalta(comp)"
                  label="Selecione uma opção"
                  dense
                  solo
                />
              </v-col>
            </v-row>
          </div>
          <div v-if="frequenciaLancada" class="text-center mb-5">
            <h3>
              {{ referencia }}
              {{
                componente_curricular != 11
                  ? "- " + $constants.componente_curriculares[componente_curricular].text
                  : ""
              }}
              {{
                tipo_falta == 0
                  ? "- Apenas as Faltas Sem justificativas"
                  : "- Apenas as Faltas Com Justificativas"
              }}
            </h3>
          </div>
          <div v-else class="text-center mb-5">
            <v-alert text border="left" color="deep-green" class="text-caption ma-3">
              <template>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >Não existe Encontros cadastrados para o Componente Curricular
                      Selecionado</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Cadastre encontros para esse componente curricular e tente novamente ou
                      selecione a Opção "Todos" para visualizar sem um componente
                      específico.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-alert>
          </div>
          <v-row v-show="frequenciaLancada">
            <v-col cols="6">
              <v-card class="mx-auto" outlined>
                <apexchart
                  height="300px"
                  width="60%"
                  :options="options"
                  :series="series"
                ></apexchart>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-alert
                v-if="matriculas.length == 0"
                text
                border="left"
                color="deep-green"
                class="text-caption ma-3"
              >
                Nenhuma Falta Detectada
              </v-alert>
              <v-simple-table v-else style="border: 0.1rem solid #f1f1f1" dense>
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #f1f1f1">
                      <th class="text-left">Nome</th>
                      <th class="text-left">CPF</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in matriculas" :key="item.id">
                      <td v-if="item.nome">{{ item.nome }}</td>
                      <td v-if="item.cpf">{{ item.cpf }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions> </v-card-actions>
      </v-card>
    </v-col>
  </impressao-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      matriculas: [],
      tipo_falta: 0,
      tipoFaltas: [
        {
          id: 0,
          text: "Faltas sem Justificativas",
        },
        {
          id: 1,
          text: "Faltas com Justificativas",
        },
      ],
      frequenciaLancada: false,
      componente_curricular: 11,
      referencia: "1º Encontro",
      totalGeralFaltas: 0,
      dataAll: null,
      temas: [],
      porcentagens: [],
      series: [],
      show: false,

      options: {
        chart: {
          foreColor: "#263238",
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 4],
        },
        plotOptions: {
          bar: {
            // horizontal: true,
            columnWidth: "35%",
            endingShape: "rounded",
          },
        },
        colors: ["#0d6efd", "#212529"],
        title: {
          text: "",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [
          "Creche",
          "Pré-Escola",
          "1º Ano",
          "2º Ano",
          "3º Ano",
          "4º Ano",
          "5º Ano",
          "6º Ano",
          "7º Ano",
          "8º Ano",
          "9º Ano",
          "AEE",
          "EJA",
        ],
        xaxis: {
          // type: "datetime",
        },
      },
    };
  },
  async mounted() {
    await this.getDataChart();
  },
  methods: {
    async changeReferencia(referencia) {
      // console.log("referencia: ", referencia);
      await this.getDataChart();
    },

    async changeCompCurricular(comp) {
      this.componente_curricular = comp.slug;
      await this.getDataChart();
    },

    async changeTipoFalta(tipo) {
      this.tipo_falta = tipo.id;
      await this.getDataChart();
    },

    async getDataChart() {
      const data = await this.$services.diarioFormacaoService.getFrequenciaRelatorioGeral(
        this.referencia,
        this.componente_curricular,
        this.tipo_falta
      );

      this.frequenciaLancada = data.frequencia_lancada;
      this.matriculas = data.matriculas;
      this.series = [
        {
          name: "Total de Presentes (%)",
          type: "column",
          data: data.dados,
        },
      ];
    },
  },
};
</script>
