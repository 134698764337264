<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1>
              <v-btn color="primary" x-small text @click="goBack">
                <v-icon left> fa fa-angle-left </v-icon>
              </v-btn>
              Encontros
            </h1>
          </v-col>
        </v-row>
        <v-card v-if="vinculo">
          <v-card-text> </v-card-text>
        </v-card>
        <v-col cols="12" class="pt-3 px-0">
          <div class="d-flex justify-space-between">
            <v-btn
              color="success"
              :to="{
                name: 'diario-formacao.encontros.create',
                params: { vinculoId: $route.params.vinculo },
              }"
              class="mr-2"
              >Criar Encontro</v-btn
            >
            <v-text-field
              v-model="search"
              class="ml-2 search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="aulasList"
          :loading="carregandoAulas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary "
                :class="aula.estaDeletado ? ['deleted'] : []"
                v-for="aula in items"
                :key="aula.id"
              >
                <td>{{ aula.id }}</td>
                <td>{{ aula.referencia }}</td>
                <td>{{ aula.data.split("-").reverse().join("/") }}</td>

                <td>{{ aula.horario_inicio | hour }}</td>
                <td>
                  {{ aula.horario_final | hour }}
                </td>
                <td class="text-right">
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => verMotivoEncontro(aula)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar aula </span>
                  </v-tooltip>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => lancarFrequencia(aula)"
                      >
                        <v-icon small> mdi-file-pdf </v-icon>
                      </v-btn>
                    </template>
                    <span>Frequencia</span>
                  </v-tooltip>
                  <v-tooltip color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarEncontro(aula)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
      <v-bottom-sheet v-model="aulasEmConflitos" scrollable>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Fechar
          </v-btn>

          <v-alert
            class="mt-2"
            style="text-align: center"
            type="orange"
            v-if="aulasConflitadas.length <= 0"
          >
            Procurando aulas em conflitos
          </v-alert>

          <h2 class="mt-4">Aulas conflitadas</h2>

          <!-- <div style="width: 100%; text-align: left">
              <v-chip color="orange" label dark><v-icon left> mdi-label </v-icon> </v-chip>
            </div> -->

          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Professor</th>
                  <th class="text-left">Situação</th>
                  <th class="text-left">Data/Horario</th>
                  <th class="text-left">Turma</th>
                  <th class="text-left">Componentes Curriculares</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr v-for="item in aulasConflitadas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.professor?.nome }}
                    <br />
                    <v-chip color="grey" dark x-small>
                      {{ item.turma.franquia.descricao }}
                    </v-chip>
                  </td>
                  <td>{{ item.situacao }}</td>
                  <td>
                    <span>
                      {{ item.data.split("-").reverse().join("/") }}
                    </span>
                    - {{ item.horario.descricao }}
                  </td>
                  <td>{{ item.turma.descricao }}</td>
                  <td>{{ item.disciplina.descricao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </v-row>
    <drawer-motivo-encontro v-model="verMotivo" :aula="aula"> </drawer-motivo-encontro>
    <!-- Dialog -->
    <e-modal-select-month-aula
      :dialog="dialog"
      @dialogChange="dialog = $event"
      :gestao_id="$route.params.vinculo"
      :tipo_diario="tipo_diario"
    ></e-modal-select-month-aula>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";
import drawerMotivoEncontro from "@/components/Encontros/drawerMotivoEncontro.vue";

export default {
  components: {
    drawerMotivoEncontro,
  },
  data() {
    return {
      aulasConflitadas: [],
      aulasEmConflitos: false,
      dataTableFilter,
      aulasList: [],
      carregandoAulas: false,
      vinculos: null,
      verMotivo: false,
      dialog: false,
      tipo_diario: 0,
      aula: {},
      vinculo: null,
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Referência", value: "referencia" },
          { text: "Data", value: "data_formatada" },
          { text: "Início", value: "horario_inicial" },
          { text: "Fim", value: "horario_final" },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    async deletarEncontro(encontro) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta encontro?",
        confirmationCode: encontro.id,
        confirmationMessage: `Por favor, digite <strong>${encontro.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a encontro");
              try {
                await this.$services.diarioFormacaoService.deletarEncontro(encontro);
                this.$toast.success("encontro deletado com sucesso");
                this.loadData();
                // this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },

    verMotivoEncontro(aula) {
      this.verMotivo = !this.verMotivo;
      this.aula = aula;
    },
    goBack() {
      this.$router.push({ name: "diario-formacao.vinculos" });
    },
    lancarFrequencia(aula) {
      this.$router.push({
        name: "diario-formacao.encontros.frequencia",
        params: { aulaId: aula.id },
      });
    },
    openModalMonth(tipo_diario) {
      this.dialog = true;
      this.tipo_diario = parseInt(tipo_diario, 10);
    },
    async loadData() {
      this.carregandoAulas = true;
      this.$loaderService.open("Carregando Aulas");
      try {
        const response = await this.$services.diarioFormacaoService.encontros(
          this.$route.params.vinculo
        );

        this.aulasList = response;

        // formatar data
        this.aulasList = this.aulasList.map((aula) => ({
          ...aula,
        }));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
      this.carregandoAulas = false;
    },
  },
};
</script>

<style></style>
