<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'saec.franquia', params: { franquia_id } })"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Questões Para Prova Saec
        </h1>
      </v-col>
      <v-col cols="12">
        <v-alert
          border="left"
          elevation="1"
          prominent
          shaped
          text
          dense
          type="success"
          color="green"
          class="ps-5 py-5"
        >
          <div class="text-h5">Resumo</div>
          <div class="text-subtitle-2">
            {{ franquia?.descricao }}
          </div>
          <div class="text-subtitle-2">
            {{ turmas.length }} turmas cadastradas na escola, totalizando {{ series.length }} series
          </div>
          {{ edicaoSaec?.descricao }}
        </v-alert>
        <v-row class="pa-8">
          <v-col class="py-0" cols="12" md="4" v-for="serie in series" :key="serie.id">
            <v-alert
              elevation="1"
              prominent
              shaped
              text
              dense
              color="green white--text"
              rounded
              class="pt-5"
            >
              Série: {{ serie?.descricao }} <br />
              Possui
              {{ edicaoSaec?.prova.filter((prova) => prova.serie_id === serie.id).length }}
              questões lançadas
              <v-btn
                color="primary"
                depressed
                elevation="0"
                block
                plain
                tile
                @click="indexQuestoesSaecDaSerie(serie.id)"
                class="my-2"
              >
                Ver detalhes
              </v-btn>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      franquia_id: "",
      franquia: null,
      edicaoSaec: {},
      edicao_id: "",
      series: [],
      turmas: [],
    };
  },
  async mounted() {
    this.franquia_id = this.$route.params.franquia_id;
    this.edicao_id = this.$route.params.edicao_id;
    this.$loaderService.open("Carregando dados");
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.edicaoSaec = await this.$services.saecService.getUmaEdicaoSaec(this.edicao_id);
      this.franquia = await this.$services.franquiasService.getFranquia(this.franquia_id);
      this.series = await this.$services.saecService.getSeries(this.franquia_id);
      this.turmas = [
        ...(await this.$services.saecService.getTurmasFundamental(this.franquia_id)),
        ...(await this.$services.saecService.getTurmasInfantil(this.franquia_id)),
      ];
      this.series = this.series.filter((serie) => {
        return this.turmas.map((turma) => turma.serie_id).includes(serie.id);
      });
      this.$loaderService.close();
    },
    lancarNotaDaTurma(turma_id) {
      this.$router.push({
        name: "saec.lancarNotasDaTurma",
        params: {
          franquia_id: this.franquia_id,
          edicao_id: this.edicaoSaec.id,
          turma_id,
        },
      });
    },
    indexQuestoesSaecDaSerie(serie_id) {
      this.$router.push({
        name: "saec.lancarQuestoesSaecDaSerie",
        params: {
          franquia_id: this.franquia.id,
          edicao_id: this.edicaoSaec.id,
          serie_id,
        },
      });
    },
    alpabethicalOrder(a, b) {
      return a.descricao.localeCompare(b.descricao);
    },
  },
};
</script>

<style></style>
