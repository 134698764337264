<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.push({ name: 'franquias' })">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Editando Horários
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-banner color="secondary" elevation="5" single-line icon="fa fa-school" rounded sticky>
          <h4>{{ franquia.descricao }}</h4>
        </v-banner>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(tipos_de_horarios, indexExterno) of $constants.tipos_de_horarios"
            :key="indexExterno"
          >
            <v-expansion-panel-header class="d-flex my-5 justify-space-between">
              <div>
                <v-btn color="error" class="ps-3" x-small text fab> </v-btn>
              </div>
              <div>HORÁRIO {{ tipos_de_horarios.descricao.toString().toUpperCase() }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="(horario, indexInterno) in form.filter(
                  (horario) => horario.tipo_horario.id == tipos_de_horarios.id
                )"
                :key="indexInterno"
                class="my-5 justify-space-between"
              >
                <div>
                  <v-row>
                    <v-col cols="12">
                      <v-sheet
                        color="amarelo_claro"
                        elevation="9"
                        rounded
                        class="d-flex py-5 px-2"
                        shaped
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-text-field
                              v-model="form[indexInterno].descricao"
                              label="Descrição"
                              dense
                              solo
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <e-autocomplete
                              label="Turno"
                              v-model="form[indexInterno].turno_id"
                              :items="$constants.turnos"
                              :item-text="(item) => item.descricao"
                              :item-value="(item) => item.id"
                              dense
                            />
                          </v-col>
                          <v-col cols="4">
                            <e-autocomplete
                              label="Tipo de Horário"
                              v-model="form[indexInterno].tipo_horario"
                              :items="$constants.tipos_de_horarios[indexExterno]"
                              :item-text="(item) => item.descricao"
                              :item-value="(item) => item.descricao"
                              dense
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="form[indexInterno].inicio"
                              label="Horario de Início"
                              dense
                              solo
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="form[indexInterno].final"
                              label="Horario de Término"
                              dense
                              solo
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <div class="ps-3 pt-2">
                          <v-btn
                            color="error"
                            class="ps-3"
                            x-small
                            text
                            fab
                            @click.stop.prevent="excluirHorario(indexInterno)"
                          >
                            <v-icon left>fa fa-trash</v-icon>
                          </v-btn>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-btn
                color="info"
                class="my-5"
                block
                type="submit"
                @click.stop.prevent="adicionarNovoHorario(indexExterno)"
              >
                Adicionar novo Horário
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-btn
          color="primary"
          class="my-5"
          :disabled="horariosAgrupadosList.length < 1"
          block
          type="submit"
          @click.stop.prevent="submitForm()"
        >
          Salvar Horários
        </v-btn>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      horariosAgrupadosList: [],
      franquia: {},
      form: [],
      grupos: [],
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.horariosAgrupadosList = await this.$services.horariosService.getHorariosDaFranquia(
        this.$router.currentRoute.params.franquia_id
      );
      this.franquia = await this.$services.franquiasService.getFranquia(
        this.$router.currentRoute.params.franquia_id
      );
      this.grupos = this.horariosAgrupadosList;
      this.updateForm();
    },
    updateForm() {
      // for (let i = 0; i < this.grupos.length; i++) {
      //   this.grupos[i].forEach((horario) => {
      //     this.form.push(horario);
      //   });
      // }
    },
    submitForm() {},
    adicionarNovoTipoDeHorarios() {
      this.form.push({});
    },
    adicionarNovoHorario(tipo_horario) {
      this.form.push({
        tipo_horario: this.$constants.tipos_de_horarios[tipo_horario],
      });
      this.updateForm();
    },
    excluirHorario(index) {
      this.form.splice(index, 1);
    },
  },
};
</script>

<style></style>
