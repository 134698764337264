<template>
  <div class="template-wrapper">
    <div class="main-content">
      <v-container>
        <div class="elemento-para-ocultar mb-5">
          <h2 class="text-center">Relatórios para Impressão</h2>
          <v-btn color="default" rounded @click="voltar()"> voltar </v-btn>
        </div>
        <slot />
        <v-row style="min-height: 90px">
          <v-col>
            <div class="elemento-para-ocultar">
              <v-card-actions>
                <v-btn color="success" rounded @click="imprimir()"> Imprimir </v-btn>
              </v-card-actions>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    imprimir() {
      window.print();
    },
    voltar() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
@media print {
  @page {
    size: landscape;
  }
  .elemento-para-ocultar {
    display: none;
  }
}
</style>
