import store from "@/store";
import { $loaderService } from "@/plugins/ELoaderService";

const responsavel = async (to, from, next) => {
  let currentUser = store.getters["Auth/user"];

  if (!currentUser) {
    await $loaderService.loadBasicDataIfNeeded();
  }

  currentUser = store.getters["Auth/user"];
  // eslint-disable-next-line
  if (!currentUser) {
    next({ name: "login" });
  } else if (currentUser.roleName === "aluno(a)") {
    next({ name: "acessoResponsavel.home" });
  } else if (currentUser.roleName === "cuidador") {
    next({ name: "acessoCuidador.home" });
  }
};

export default responsavel;
