<template>
  <div class="wrapper">
    <v-card elevation="2" class="login-card">
      <v-card-title class="d-flex justify-center">
        <img src="/img/logo.png" width="100" />
      </v-card-title>
      <v-card-text>
        <v-col class="pb-8" v-if="!question">
          <h2 class="text-center">Para acessar o sistema, você usa o seu email?</h2>
          <v-row>
            <v-col class="pt-8">
              <v-btn color="primary" block @click="changeScreen(1)">Sim</v-btn>
            </v-col>
            <v-col class="pt-8">
              <v-btn color="error" block @click="changeScreen(2)">Não</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <ValidationObserver v-slot="{ handleSubmit }" v-if="hasEmail">
          <v-form ref="form" @submit.prevent="handleSubmit(resetPassword)">
            <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.email"
                filled
                prepend-inner-icon="mdi-email-outline"
                label="Email"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider name="CPF" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.cpf"
                filled
                v-mask="'###.###.###-##'"
                prepend-inner-icon="mdi-account-outline"
                label="CPF"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider name="Data de Nascimento" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.data_nascimento"
                filled
                v-mask="'##/##/####'"
                prepend-inner-icon="mdi-calendar-outline"
                label="Data de Nascimento"
                :error-messages="errors"
                required
              />
            </ValidationProvider>
            <v-btn
              block
              color="primary"
              type="submit"
              :class="loginButtonClass"
              :disabled="logando"
              :loading="logando"
            >
              Recuperar Senha
            </v-btn>
          </v-form>
        </ValidationObserver>
        <ValidationObserver v-slot="{ handleSubmit }" v-if="dontHaveEmail">
          <v-form ref="form" @submit.prevent="handleSubmit(resetPassword)">
            <ValidationProvider name="CPF" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.cpf"
                filled
                v-mask="'###.###.###-##'"
                prepend-inner-icon="mdi-account-outline"
                label="CPF"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider name="Data de Nascimento" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.data_nascimento"
                filled
                v-mask="'##/##/####'"
                prepend-inner-icon="mdi-calendar-outline"
                label="Data de Nascimento"
                :error-messages="errors"
                required
              />
            </ValidationProvider>
            <v-btn
              block
              color="primary"
              type="submit"
              :class="loginButtonClass"
              :disabled="logando"
              :loading="logando"
            >
              Recuperar Senha
            </v-btn>
          </v-form>
        </ValidationObserver>
        <a
          @click="$router.push('/login')"
          class="help-section d-flex flex-column align-center"
          v-bind:class="{ 'animate__animated animate__headShake': animarAjuda }"
        >
          <span class="mt-4">Voltar para o login!</span>
        </a>
      </v-card-text>
    </v-card>
    <e-modal-info
      v-if="showModal"
      :showModal="showModal"
      :dadosModal="dadosModal"
      @closeModal="onClose()"
    />
  </div>
</template>

<script>
export default {
  name: "ResetPasswordPage",
  data() {
    return {
      credentials: {},
      logando: false,
      loginButtonClass: "",
      animarAjuda: false,
      hasEmail: false,
      dontHaveEmail: false,
      question: false,
      dadosModal: {},
      showModal: false,
    };
  },
  mounted() {
    const switchAnimarAjuda = () => {
      this.animarAjuda = !this.animarAjuda;
      setTimeout(() => switchAnimarAjuda(), 2000);
    };
    switchAnimarAjuda();
  },
  methods: {
    onClose() {
      this.showModal = false;
      this.$router.push({ name: "home" });
      setTimeout(() => window.location.reload(), 100);
    },
    renderModal(dados) {
      this.showModal = true;
      this.dadosModal = dados;
    },
    resetPassword() {
      this.logando = true;
      this.$services.authService
        .resetPassword(this.credentials)
        .then(async (response) => {
          this.$toast.success(response.data.message);
          await this.$services.authService.logar(
            response.data.credentials,
            await this.$services.webInfo.pegarIpLocalDoUsuario(),
            this.$services.webInfo.pegarDispositivoDoUsuario()
          );
          this.renderModal({
            titulo: "Senha Alterada com Sucesso",
            mensagem1: "Sua nova senha é a sua data de nascimento.",
            mensagem2: `Senha: ${response.data.credentials.password}`,
            botao: "Confirmar",
          });
        })
        .catch((err) => {
          this.$toast.danger(err.message || err.error.message);
          this.loginButtonClass = "animate__animated animate__headShake";
          setTimeout(() => (this.loginButtonClass = ""), 500);
        });

      this.logando = false;
    },
    changeScreen(screen) {
      if (screen === 1) {
        this.hasEmail = !this.hasEmail;
      } else {
        this.dontHaveEmail = !this.dontHaveEmail;
      }
      this.question = !this.question;
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-image: url("../../assets/background.png");
  background-size: cover;
}
.wrapper > .login-card {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.7);
}

.help-section > i {
  margin-top: 12px;
  margin-bottom: 4px;
  margin-left: 50%;
  margin-right: 50%;
  transform: translateX(-50%);
}

.help-section {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
}
</style>
