<template>
  <main-template>
    <v-row dense>
      <v-col cols="12">
        <v-card color="success" dark>
          <v-card-title class="text-h5"> Diário de Formadores </v-card-title>

          <v-card-subtitle
            >Siga o passo-a-passo abaixo para melhor usabilidade do Diário.</v-card-subtitle
          >
        </v-card>
      </v-col>
    </v-row>
    <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item
        v-for="(item, i) in items"
        :key="i"
        :color="item.color"
        :icon="item.icon"
        fill-dot
      >
        <v-card :color="item.color" dark>
          <v-card-title class="text-h6">
            {{ item.title }}
          </v-card-title>
          <v-card-text class="white text--primary">
            <p>{{ item.text }}</p>
            <v-btn
              :color="item.color"
              class="mx-0"
              outlined
              :to="{
                name: item.route,
              }"
            >
              Cadastrar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </main-template>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Formadores",
        text: "Primeiro passo é cadastrar os formadores",
        color: "red lighten-2",
        icon: "mdi-numeric-1",
        route: "diario-formacao.formadores.index",
      },
      {
        title: "Turmas",
        text: "Segundo passo é cadastrar as turmas",
        color: "purple darken-1",
        icon: "mdi-numeric-2",
        route: "diario-formacao.turmas.index",
      },
      {
        title: "Criar Vínculo do Formador com a Turma",
        text: "Aqui você vai definir as turmas que o formador vai ser vinculado.",
        color: "green lighten-1",
        icon: "mdi-numeric-3",
        route: "diario-formacao.vinculos.create",
      },
      {
        title: "Criar Matrículas",
        text: "Nessa etapa você vai criar as matrículas dos professores junto às turmas",
        color: "indigo",
        icon: "mdi-numeric-4",
        route: "diario-formacao.matriculas.create",
      },
    ],
  }),
};
</script>
