import { $toast } from "vuetify-snackbar-toast";

export default function $handleError(error) {
  if (!error.message?.includes("Unauthenticated")) {
    $toast.danger(error.message ?? error.error.message);
  }
}

export const handleErrorPlugin = (vue) => {
  vue.prototype.$handleError = $handleError;
};
