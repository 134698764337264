import Axios from "@/plugins/Axios";
import Aula from "@/Models/Aula";

export class AulasService {
  async syncAll({ turma_id, disciplina_id }) {
    const response = await Axios().get("aulas", { params: { turma_id, disciplina_id } });
    const aulas = response.data.map((aula) => new Aula(aula));
    return aulas;
  }

  async listarAulasAguardandoConfirmacao() {
    const response = await Axios().get("aulas/listar-aulas-aguardando-confirmacao");
    const aulas = response.data.data.map((aula) => new Aula(aula));
    return aulas;
  }

  async listarAulasAguardandoConfirmacaoPaginate(pageNumber, query) {
    const response = await Axios().get(
      `aulas/listar-aulas-aguardando-confirmacao?page=${pageNumber}&query=${query}`
    );
    // const aulas = response.data.data.map((aula) => new Aula(aula));
    return response.data;
  }

  async countBotoesHomer() {
    const response = await Axios().get("aulas/count-botoes-homer");
    return response.data;
  }

  async registrarPresenca(aula, { matricula_id, presente }) {
    const response = await Axios().post(`aulas/${aula.id}/registrar-presenca`, {
      matricula_id,
      presente,
    });
    return response.data;
  }

  async justificarFalta(
    aula,
    { observacao = "", file = null, matriculaSelect = null, justificativa_id = null }
  ) {
    const formData = new FormData();
    formData.append("observacao", observacao);
    formData.append("file", file);
    formData.append("matricula_id", matriculaSelect.id);
    formData.append("justificativa_id", justificativa_id);

    Axios().interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });

    const response = await Axios().post(`aulas/${aula.id}/justificar-falta`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async criar(aula) {
    const response = await Axios().post("aulas", aula);
    const novaAula = new Aula(response.data);
    return novaAula;
  }

  async getAulaComRelacoes(aula_id) {
    const response = await Axios().get(`aulas/${aula_id}`);
    return new Aula(response.data);
  }

  async getAula(aula_id) {
    const response = await Axios().get(`aulas/get-aula/${aula_id}`);
    return new Aula(response.data.aula);
  }

  async atualizar(aula) {
    const response = await Axios().put(`aulas/${aula.id}`, aula);
    const aulaAtualizada = response.data;
    return new Aula(aulaAtualizada);
  }

  async atualizarRegistroNotifiq(aula) {
    const response = await Axios().put(`aulas/atualizar-registro-notifiq/${aula.id}`, aula);
    const aulaAtualizada = response.data;
    return new Aula(aulaAtualizada);
  }

  async deletar(aula) {
    const response = await Axios().delete(`aulas/${aula.id}`);
    const aulaDeletada = response.data;
    return aulaDeletada;
  }

  async atualizarSituacao(aula, novaSituacao) {
    const response = await Axios().put(`aulas/atualizarSituacao/${aula.id}`, {
      ...aula,
      situacao: novaSituacao,
    });
    const aulaAtualizada = response.data;
    return new Aula(aulaAtualizada);
  }

  async recuperar(aula) {
    return this.atualizarAula({ ...aula, deleted_at: null });
  }

  async justificativas() {
    const response = await Axios().get("aulas/falta/justificativas");
    return response.data;
  }

  async listarAulasEmConflitos(aula_id) {
    const response = await Axios().get(`aulas/listar-aulas-conflitos/${aula_id}`);
    return response;
  }

  async pesquisarAulasEmConflitos(data, horario_id, turma_id, aula_id) {
    const response = await Axios().get(
      `aulas/verificar-aula-existente/${data}/${parseInt(horario_id, 10)}/${parseInt(
        turma_id,
        10
      )}/${aula_id}`
    );
    return response;
  }

  async graficoDeAulasDaSemana(franquia_id) {
    const response = await Axios().get(`aulas/grafico/aulasDaSemana/${franquia_id}`);
    return response;
  }

  async graficoDeAulasConfirmadasDaSemana(franquia_id) {
    const response = await Axios().get(`aulas/grafico/aulasConfirmadasDaSemana/${franquia_id}`);
    return response;
  }

  async graficoDeAulasLancadasEConfirmadas(franquia_id) {
    const response = await Axios().get(`aulas/grafico/aulasLancadasEConfirmadas/${franquia_id}`);
    return response;
  }

  async graficoDeAulasLancadasEConfirmadasDoMunicipio() {
    const response = await Axios().get(`aulas/grafico/aulasLancadasEConfirmadasDoMunicipio`);
    return response;
  }
}
export default new AulasService();
