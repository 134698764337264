import Axios from "@/plugins/Axios";
import store from "@/store";
import SubEtapa from "@/Models/SubEtapa";

export class PlanosEnsinoService {
  async filterHabilidades(idDisciplina) {
    const response = await Axios().get(`planos-ensino/filter-habilidades/${idDisciplina}`);
    const habilidades = response.data;
    return habilidades;
  }

  async syncAll() {
    const response = await Axios().get("planos-ensino");
    const planos = response.data;
    return planos;
  }

  async criar(plano) {
    const response = await Axios().post("planos-ensino", plano);
    const novoPlano = response.data;
    return novoPlano;
  }

  async getMeses(gestaoId) {
    const response = await Axios().get(`planos-ensino/get-meses/${gestaoId}`);
    const plano = response.data;
    return plano;
  }

  async baixarPlano(gestaoId, mes) {
    const response = await Axios()
      .get(`/baixar-planos-ensino/${gestaoId}/${mes}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `plano_ensino_${gestaoId}.pdf`;
        link.click();
        // window.open(link.href, "_blank");
      });
    return response;
  }

  async editar(plano) {
    const response = await Axios().put(`planos-ensino/${plano.id}`, plano);
    const planoEditado = response.data;
    return planoEditado;
  }

  async deletar(dado) {
    const response = await Axios().delete(`planos-ensino/${dado.id}`);
    const subEtapaDeletada = response.data;
    return subEtapaDeletada;
  }
}
export default new PlanosEnsinoService();
