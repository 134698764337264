import PlanosEnsinoIndexPage from "@/Pages/PlanosEnsino/index.vue";
import NewEditPlanosEnsinoPage from "@/Pages/PlanosEnsino/NewEditPlanosEnsinoPage.vue";

export default [
  {
    path: "/planos-ensino/",
    name: "planosEnsino",
    component: PlanosEnsinoIndexPage,
  },
  {
    path: "/planos-ensino/create",
    name: "planosEnsino.create",
    component: NewEditPlanosEnsinoPage,
    props: { editing: false },
  },
  {
    path: "/planos-ensino/edit/:plano_id",
    name: "planosEnsino.edit",
    component: NewEditPlanosEnsinoPage,
    props: { editing: true },
  },
  {
    path: "/planos-ensino/show/:plano_id",
    name: "planosEnsino.show",
    props: { editing: true },
  },
];
