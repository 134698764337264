import DiarioFormacaoIndexPage from "@/Pages/DiarioFormacao/index.vue";
import DiarioFormacaoWelcomePage from "@/Pages/DiarioFormacao/WelcomePage.vue";
import DiarioFormacaoVinculoCreatePage from "@/Pages/DiarioFormacao/VinculoCreatePage.vue";
import DiarioFormacaoEncontrosCreatePage from "@/Pages/Encontros/EncontrosCreatePage.vue";
import DiarioFormacaoEncontrosPage from "@/Pages/Encontros/EncontrosIndexPage.vue";
import TurmasFormacaoIndexPage from "@/Pages/TurmasFormacao/TurmasFormacaoIndexPage.vue";
import TurmasFormacaoCreatePage from "@/Pages/TurmasFormacao/TurmasFormacaoCreatePage.vue";
import FormadoresIndexPage from "@/Pages/Formadores/FormadoresIndexPage.vue";
import FormadoresCreatePage from "@/Pages/Formadores/FormadoresCreatePage.vue";
import MatriculasFormacaoCreatePage from "@/Pages/MatriculasFormacao/MatriculasFormacaoCreatePage.vue";
import MatriculasFormacaoIndexPage from "@/Pages/MatriculasFormacao/MatriculasFormacaoIndexPage.vue";
import FrequenciaFormacao from "@/Pages/FrequenciaFormacao/FrequenciaFormacao.vue";
import RelatorioFrequenciaVinculo from "@/Pages/FrequenciaFormacao/RelatorioFrequenciaVinculo.vue";
import RelatorioFrequenciaGeral from "@/Pages/RelatoriosFormacao/RelatorioFrequenciaGeral.vue";

export default [
  {
    path: "/diario-formacao/inicio",
    name: "diario-formacao.welcome",
    component: DiarioFormacaoWelcomePage,
  },
  {
    path: "/diario-formacao/vinculos",
    name: "diario-formacao.vinculos",
    component: DiarioFormacaoIndexPage,
  },
  {
    path: "/diario-formacao/vinculo-create",
    name: "diario-formacao.vinculos.create",
    component: DiarioFormacaoVinculoCreatePage,
  },

  // encontros
  {
    path: "/diario-formacao/:vinculo/encontros",
    name: "diario-formacao-encontros",
    component: DiarioFormacaoEncontrosPage,
    props: true,
  },
  {
    path: "/diario-formacao/:vinculo/encontros-create",
    name: "diario-formacao.encontros.create",
    component: DiarioFormacaoEncontrosCreatePage,
  },
  {
    path: "/diario-formacao/:vinculo/relatorio-frequencia-vinculo",
    name: "diario-formacao.encontros.relatorio.vinculo",
    component: RelatorioFrequenciaVinculo,
  },
  {
    path: "/diario-formacao/relatorios-frequencia-geral",
    name: "diario-formacao.relatorios.frequencia.geral",
    component: RelatorioFrequenciaGeral,
  },

  // frequencia
  {
    path: "/diario-formacao/:aulaId/frequencia",
    name: "diario-formacao.encontros.frequencia",
    component: FrequenciaFormacao,
    props: true,
  },

  // turmas formacao
  {
    path: "/diario-formacao/turmas/",
    name: "diario-formacao.turmas.index",
    component: TurmasFormacaoIndexPage,
  },
  {
    path: "/diario-formacao/turmas/",
    name: "diario-formacao.turmas.create",
    component: TurmasFormacaoCreatePage,
  },

  // formadores
  {
    path: "/diario-formacao/formadores/",
    name: "diario-formacao.formadores.index",
    component: FormadoresIndexPage,
  },
  {
    path: "/diario-formacao/formadores/",
    name: "diario-formacao.formadores.create",
    component: FormadoresCreatePage,
  },

  // matriculas
  {
    path: "/diario-formacao/matriculas/index/",
    name: "diario-formacao.matriculas.index",
    component: MatriculasFormacaoIndexPage,
  },
  {
    path: "/diario-formacao/matriculas/create/",
    name: "diario-formacao.matriculas.create",
    component: MatriculasFormacaoCreatePage,
  },
];
