<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'diario-formacao.turmas.index' })"
            :disabled="criandoTurma"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Turma de Formação
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="criandoTurma">
        <v-row>
          <v-col class="pt-0 pb-0" cols="8">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <e-label>Descrição</e-label>
              <v-text-field :error-messages="errors" v-model="form.descricao" dense solo />
            </ValidationProvider>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="criandoTurma"
              :loading="criandoTurma"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // this.loadData();
  },
  mounted() {
    this.loadData();
  },
  watch: {
    switch1(newVal) {
      if (newVal) {
        this.form.notifiq = 1;
        return;
      }
      this.form.notifiq = 0;
    },
  },
  data() {
    return {
      form: {},
      seriesList: [],
      turnosList: [],
      sistemaBNCCList: [],
      tiposDeEnsinosList: [],
      criandoTurma: false,
      carregandoSeries: false,
      carregandoTurnos: false,
      carregandoTiposDeEnsinos: false,
      carregandoSistemaBNCC: false,
      switch1: true,
      circuitoDeNotasList: [],
      carregandoCircuito: false,
    };
  },
  methods: {
    alterarSistemaBNCC() {
      if (!this.form.isInfantil) this.form.sistema_bncc_id = null;
    },
    async submitForm() {
      try {
        if (this.editing) {
          await this.$services.diarioFormacaoService.atualizarTurma(this.form);
          this.$toast.success("Turma Atualizada com sucesso");
        } else {
          await this.$services.diarioFormacaoService.criarTurma(this.form);
          this.$toast.success("Turma criada com sucesso");
        }
        this.$router.push({ name: "diario-formacao.turmas.index" });
      } catch (error) {
        this.$handleError(error);
      }
      this.criandoTurma = false;
    },
    async loadData() {
      this.$loaderService.open("Carregando dados");
      try {
        if (this.editing) {
          const { turma_id } = this.$route.params;
          const payload = await this.$services.turmasService.visualizarTurma(turma_id);
          const series = await payload.turma.series.map((serie) => (serie.id ? serie.id : serie));
          this.form = { ...payload.turma, serieIdList: series };
          this.switch1 = parseInt(payload.turma.notifiq, 10) === 1;
        }
        this.tiposDeEnsinosList = await this.$services.tiposDeEnsinosService.syncAll();
        this.turnosList = await this.$services.turnosService.syncAll();
        this.seriesList = await this.$services.seriesService.syncAll();
        this.circuitoDeNotasList = await this.$services.circuitoNotasService.syncAll();
        const response = await this.$services.sistemaBNCCService.listarTodos();
        this.sistemaBNCCList = response.sistema;
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
