<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'saec.listarEdicoes',
                })
            "
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editar" : "Criar" }} Edição Saec
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)">
        <v-row class="ma-3">
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Nome da Edição" rules="required" v-slot="{ errors }">
              <e-label>Nome da Edição</e-label>
              <v-text-field
                v-model="form.descricao"
                :error-messages="errors"
                label="Sistema de Avaliação da Educação de Castelo - SAEC - 1ª Edição"
                solo
                chips
                small-chips
              ></v-text-field>
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Nome da Edição" rules="required" v-slot="{ errors }">
              <e-label>Nome da Edição</e-label>
              <!-- :disabled="true" -->
              <e-autocomplete
                v-model="form.ano_id"
                :items="[user.ano]"
                :error-messages="errors"
                :item-value="(item) => item.id"
                :item-text="(item) => item.descricao"
                label="Ano desta Edição"
                solo
                chips
                small-chips
              ></e-autocomplete>
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="6">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="data"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <e-label>Selecione uma data</e-label>
                <v-text-field
                  v-model="form.data_prova"
                  persistent-hint
                  label="Selecione a data da Prova"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  solo
                ></v-text-field>
              </template>
              <v-date-picker v-model="data" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="customEvent(data)"> OK </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Salvar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      edicao_id: null,
      form: {},
      edicaoSaec: {},
      submittingForm: false,

      modal: false,
      data: null,
      dialog: {},
    };
  },
  computed: {
    editing() {
      return this.$route.params.edicao_id !== "new";
    },
    ...mapGetters("Auth", ["user"]),
  },
  async mounted() {
    this.edicao_id = this.$route.params.edicao_id;
    await this.loadData();
  },
  watch: {
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(new Date(value));
      this.form.data_prova = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    async loadData() {
      this.$loaderService.open("Carregando dados da edição saec");
      if (this.editing) {
        this.edicaoSaec = await this.$services.saecService.getUmaEdicaoSaec(this.edicao_id);
        this.form = this.edicaoSaec;
      }
      this.$loaderService.close();

      // this.form.edicao_id = this.edicao_id;
      // this.form.ano_id = this.user.ano.id;
    },
    async submitForm() {
      this.submittingForm = true;
      this.form.data_prova = this.form.data_prova.split("/").reverse().join("-");
      try {
        if (this.editing) {
          await this.$services.saecService.editarEdicaoSaec(this.edicao_id, this.form);
        } else {
          await this.$services.saecService.criarEdicaoSaec(this.form);
        }
        this.$toast.success("Dados salvos com sucesso");

        this.$router.push({
          name: "saec.listarEdicoes",
        });
      } catch (error) {
        this.$toast.danger(Object.values(error.errors)[0].toString());
      } finally {
        this.submittingForm = false;
      }
    },
    customEvent(data) {
      this.$refs.dialog.save(data);
    },
  },
};
</script>

<style></style>
