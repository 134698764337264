<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'saec.franquia', params: { franquia_id } })"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Lançar Notas Saec
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
          border="left"
          elevation="1"
          prominent
          shaped
          text
          dense
          type="success"
          color="green"
          class="ps-5 py-5"
        >
          <div class="text-h5">{{ edicaoSaec?.descricao }}</div>
          <div class="text-subtitle-2">Escola: {{ franquia?.descricao }}</div>
          <div class="text-subtitle-2">Turma: {{ turma?.descricao }}</div>
        </v-alert>
      </v-col>
    </v-row>
    <v-card>
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="(disciplinas, index) in Object.keys(resumo)" :key="index">
          {{ disciplinas }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in questoesList" :key="item.id">
          <v-card flat>
            <v-data-table
              :headers="table.headers"
              :items="turma?.matriculas"
              disable-sort
              :search="search"
              hide-default-footer
              class="elevation-1"
            >
              <!-- hide-default-header -->
              <template v-slot:body="{ items }">
                <tbody name="scroll-x-transition" is="transition-group" duration="150">
                  <tr
                    color="primary"
                    :class="matricula.estaDeletado ? ['deleted'] : []"
                    v-for="matricula of items"
                    :key="matricula.id"
                  >
                    <td>{{ matricula.id }}</td>
                    <td>{{ matricula.aluno.nomecompleto }}</td>
                    <td v-for="questoes in Object.values(resumo)[tab]" :key="questoes.id">
                      <v-switch
                        v-model="respostas[matricula.id + '-' + questoes.id]"
                        inset
                        :value="false"
                        @click="
                          lancarNota(
                            matricula.id,
                            questoes.disciplina_id,
                            questoes.id,
                            turma.id,
                            edicaoSaec?.id,
                            respostas[matricula.id + '-' + questoes.id]
                          )
                        "
                      ></v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      franquia_id: "",
      franquia: null,
      edicaoSaec: {},
      turma_id: "",
      search: "",
      questoesList: [],
      respostas: [],
      resumo: [],
      tab: 0,
      turma: null,
      table: {
        headers: [
          { text: "Matricula", value: "id" },
          { text: "Aluno", value: "descricao" },
        ],
      },
    };
  },
  mounted() {
    this.franquia_id = this.$route.params.franquia_id;
    this.edicao_id = this.$route.params.edicao_id;
    this.turma_id = this.$route.params.turma_id;
    this.loadData();
  },
  watch: {
    tab() {
      this.updateHeaders();
    },
  },
  methods: {
    async loadData() {
      this.$loaderService.open("Carregando dados");
      this.edicaoSaec = await this.$services.saecService.getUmaEdicaoSaec(this.edicao_id);
      this.franquia = await this.$services.franquiasService.getFranquia(this.franquia_id);
      const { turma } = await this.$services.saecService.getTurma(this.turma_id);
      this.turma = turma;
      [this.questoesList, this.resumo] = await this.$services.saecService.getQuestoesDaProva(
        this.edicao_id,
        this.turma.serie_id
      );
      this.updateHeaders();
      this.$loaderService.close();
    },
    updateHeaders() {
      this.table.headers = [];
      this.table.headers.push({
        text: "Matricula",
        value: "id",
      });
      this.table.headers.push({
        text: "Aluno",
        value: "descricao",
      });
      Object.values(this.resumo)[this.tab].foreach((questao) => {
        this.table.headers.push({
          text: questao.codigo,
          value: questao.id,
        });
      });
    },
    async lancarNota(matricula_id, disciplina_id, questoao_id, turma_id, edicao_saec_id, value) {
      // console.log(matricula_id, disciplina_id, questoao_id, turma_id, edicao_saec_id, value);
    },
  },
};
</script>

<style></style>
